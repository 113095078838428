import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {api} from "../../../../services/Main/Api";
import {Backdrop, Divider, FormControlLabel, Grid, InputAdornment, Switch, Typography} from "@mui/material";
import moment from "moment";
import SimpleSelector from "../../../../components/Shared/SimpleSelector";
import CircularProgress from "@mui/material/CircularProgress";
import AnimationGlobal from "../../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../../assets/animations/success.json";
import {useSnackbar} from "notistack";
const TerminateContractForm = ({ params, updateReqData }) => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [reqData, setReqData] = useState({
        warning_date: moment().format('YYYY-MM-DD'),
        closing_date: moment().format('YYYY-MM-DD'),
        calculate_fine: false,
        number_of_months: null,
        status:'inactivating',

    });
    const [activeBackdrop, setActiveBackdrop] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const {enqueueSnackbar} = useSnackbar();


    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    function updateData(newData) {
        updateReqData(newData);
    }

    console.log(reqData, '5')
    function handleSaveContract () {
        setOpen(false)
        setActiveBackdrop(true)
    }

        const TerminateContractUpdate = () => {
        setActiveBackdrop(true)
        api.put(`/rent-contract/calculate/fine/${params.id}/`, reqData)
            .then(response => {
                setTimeout(() => {
                    setReqData(response.data)
                    setOpen(false)
                    setIsSuccess(true)

                }, 2000);
            })
            .catch(error => {
                setError(error.response.data);
                enqueueSnackbar(error, { variant: 'error' });
                console.log(error);
            }).finally(data => {
            setTimeout(() => {
                setActiveBackdrop(false)
                setIsSuccess(false)
                updateData(reqData)
            }, 4000);
        })
    };

    const numberOptions = Array.from({length: 50}, (_, i) => ({
        value: i + 1,
        label: `${i + 1} ${(i === 0) ? 'vez o valor do aluguel' : 'vezes o valor do aluguel'}`,
    }));

    return (
        <>
            <Button
                fontSize={'1rem'}
                size={"large"}
                sx={{
                    ml: '1rem',
                    textTransform: 'none',
                    textDecoration: 'underline',
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                }}
                onClick={() => setOpen(true)}
            >
                Encerrar Contrato
            </Button>

            <Modal open={open} onClose={() => setOpen(false)}>

                <Card sx={{width:'35rem', marginTop:'15rem', marginLeft:'40%'}}>
                    <Typography
                        variant={'h6'}
                        sx={{
                            p: '1rem',
                            ml: '.5rem'
                        }}
                    >
                        Encerrar Contrato
                    </Typography>
                    <Divider sx={{ mb: '1rem' }}/>
                    <CardContent>
                        <form onSubmit={handleSubmit(TerminateContractUpdate)}>
                            <DatePicker
                                label={'Data de Aviso'}
                                value={moment(reqData?.warning_date) ?? ''}
                                onChange={(newValue) => setReqData((prevState) => ({
                                    ...prevState,
                                    warning_date: moment(newValue).format('YYYY-MM-DD')
                                }))}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            readOnly: true,
                                            placeholder: "dd/mm/aaaa",
                                        }}
                                        required
                                        size={"small"}
                                        sx={{
                                            mb: '1rem',
                                            width: '100%'
                                        }}

                                    />}
                            />

                            <DatePicker
                                label={'Data de Desocupação'}
                                value={moment(reqData?.closing_date) ?? ''}
                                onChange={(newValue) => setReqData((prevState) => ({
                                    ...prevState,
                                    closing_date: moment(newValue).format('YYYY-MM-DD')
                                }))}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            readOnly: true,
                                            placeholder: "dd/mm/aaaa",
                                        }}
                                        required
                                        size={"small"}
                                        sx={{
                                            mb: '1rem',
                                            width: '100%'
                                        }}

                                    />}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={reqData.calculate_fine}
                                        onChange={(event) => setReqData({
                                            ...reqData,
                                            calculate_fine: event.target.checked
                                        })}
                                    />
                                }
                                label="Calcular Multa"
                            />

                            {reqData.calculate_fine &&
                                <SimpleSelector
                                    label={'Quantas Vezes o Aluguel'}
                                    onlyNumbers
                                    state={reqData}
                                    setState={setReqData}
                                    keyName={'number_of_months'}
                                    simpleOptions={numberOptions}
                                    styles={{
                                        mb: '1rem',
                                        width: '100%'
                                    }}
                                />
                            }

                        </form>
                        <Button
                            // type="submit"
                            size={'small'}
                            variant={'contained'}
                            onClick={
                                () => {
                                    TerminateContractUpdate();
                                    handleSaveContract();
                                }
                            }
                        >
                            Salvar
                        </Button>
                    </CardContent>
                </Card>

            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={activeBackdrop}
            >
                { !isSuccess ? <CircularProgress color="inherit" />
                    : <AnimationGlobal
                        text={'Contrato em processo de encerramento!'}
                        size={'200px'}
                        animation={successAnimation}
                        fontStyles={{mb: '1rem'}}
                        fontSize={'20px'}
                        color="#FFFFFF"
                    />
                }
            </Backdrop>
        </>
    );
}

export default TerminateContractForm;