import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";

export const TableChargeHead = ({
    filters,
    setFilters
  }) => {

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }


  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-number' || filters?.ordering === 'number'}
            direction={filters?.ordering === '-number' ? 'desc' : 'asc'}
            onClick={() => handleFilter('number', '-number') }
          >
            Número
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
         }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-unit' || filters?.ordering === 'unit'}
            direction={filters?.ordering === '-unit' ? 'desc' : 'asc'}
            onClick={() => handleFilter('unit', '-unit') }
          >
           Imóvel
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-due_date' || filters?.ordering === 'due_date'}
            direction={filters?.ordering === '-due_date' ? 'desc' : 'asc'}
            onClick={() => handleFilter('due_date', '-due_date') }
          >
           Vencimento
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-total' || filters?.ordering === 'total'}
            direction={filters?.ordering === '-total' ? 'desc' : 'asc'}
            onClick={() => handleFilter('total', '-total') }
          >
            Total
          </TableSortLabel>
        </TableCell>
        <TableCell
          align={'center'}
          sx={{
            color: 'primary.main'
          }}
        >
           Arquivo
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableChargeHead