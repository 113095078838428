import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Box, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
import React from "react";
import Masks from "../../../../components/Shared/Masks";


const SlipOptionsRead =
  (
    {
      reqData,
    }
  ) => {

  function fineType() {
    if (reqData?.bankslip_fine_percent) {
      return 'percentual'
    } else if (reqData?.bankslip_fine_value){
      return 'fixa'
    } else {
      return ''
    }
  }

  function fineValue() {
    if (reqData?.bankslip_fine_percent || reqData?.bankslip_fine_percent === 0) {
      return <Typography>{reqData.bankslip_fine_percent}%</Typography>;
    } else if (reqData?.bankslip_fine_value || reqData?.bankslip_fine_value === 0) {
      return <Typography>{Masks.money(String(reqData.bankslip_fine_value) ?? '') ?? ''}</Typography>;
    } else {
      return <Box sx={{ color: 'error.main' }}>{<Close />}</Box>;
    }
  }

  function interestType() {
    if (reqData?.bankslip_interest_percent) {
      return 'percentual'
    } else if (reqData?.bankslip_interest_value){
      return 'fixo'
    } else {
      return ''
    }
  }

  function interestValue() {
    if (reqData?.bankslip_interest_percent || reqData?.bankslip_interest_percent === 0) {
      return <Typography>{reqData.bankslip_interest_percent}%</Typography>;
    } else if (reqData?.bankslip_interest_value || reqData?.bankslip_interest_value === 0) {
      return <Typography>{Masks.money(String(reqData.bankslip_interest_value) ?? '') ?? ''}</Typography>;
    } else {
      return <Box sx={{ color: 'error.main' }}>{<Close />}</Box>;
    }
  }


    function dueDay(){
    if (reqData?.bankslip_due_day) {
      return <Typography>{reqData?.bankslip_due_day}</Typography>
    } else {
      return <Box sx={{ color:'error.main'}}>{<Close/>}</Box>
    }
  }

  return (
    <Box
      sx={{
        mt: '1rem'
        // paddingX: '2rem',
      }}
    >
      <SimpleTypography
        label={'Geração Automática'}
        directValueFree={
          <Box
            sx={{
              color: reqData?.generate_bankslip ? 'success.main' : 'error.main'
            }}
          >
            {reqData?.generate_bankslip ? <Check /> : <Close />}
          </Box>
        }
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: reqData?.generate_bankslip_day? '-0.5rem' : '0.5rem'
        }}
      />

      <SimpleTypography
        label={'Envio para o e-mail'}
        directValueFree={
          <Box
            sx={{
              color: reqData?.send_email ? 'success.main' : 'error.main'
            }}
          >
            {reqData?.send_email ? <Check /> : <Close />}
          </Box>
        }
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: reqData?.generate_bankslip_day? '-0.5rem' : '0.5rem'
        }}
      />

      { reqData?.generate_bankslip &&
        <SimpleTypography
          label={`Gera todo dia ${reqData?.generate_bankslip_day}`}
          // directValue={reqData?.generate_bankslip_day}
          labelStyles={{
            fontSize: '13px'
            // fontWeight: 'bold'
          }}
          boxStyles={{
            // paddingX: '1rem',
            width: '100%',
            mb: '0.5rem'


          }}
        />
      }

      <SimpleTypography
        label={`Multa ${fineType()}`}
        directValueFree={fineValue()}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'


        }}
      />

      <SimpleTypography
        label={`Juros ${interestType()}`}
        directValueFree={interestValue()}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'

        }}
      />

      <SimpleTypography
        label={`Dia de Vencimento`}
        directValueFree={dueDay()}
        labelStyles={{
          fontWeight: 'bold'
        }}
        boxStyles={{
          width: '100%',
          mb: '0.5rem'

        }}
        />


    </Box>
  )
}

export default SlipOptionsRead