import React, {
  useEffect,
  useState
} from "react";
import {
  Card,
  Container,
  Grid,
  Skeleton,
  Table,
  TableContainer,
} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import UnitFilter from "../../../components/Filters/UnitFilter";
import {Helmet} from "react-helmet";
import SearchInput from "../../../components/Filters/SearchInput";
import {api} from "../../../services/Main/Api";
import {getCondoId} from "../../../services/Main/AuthStorage";
import TableChargeHead from "./components/ChargeList/TableChargeHead";
import TableChargeBody from "./components/ChargeList/TableChargeBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import ChargeCreate from "./ChargeCreate";


const ChargeList = () => {
  const [filters, setFilters] = useState(
    {
      condo__id: getCondoId(),
      page: 1,
      page_size: 50,
    }
  )
  const [data, setData] = useState(null)
  const [createEvent, setCreateEvent] = useState(null)

  async function fetchData() {
    try {
      if (filters?.condo__id) {
        const response = await api.get('/charge/', { params: filters });
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(function whenPageLoad (){
    fetchData()
  },[createEvent, filters])


  return(
    <>
      <Helmet>
        <title>Cobranças - Bloco B3</title>
      </Helmet>
      <Container
        maxWidth={false}
      >
        <TitleBtnList
          title={'Cobrança Individual'}
          icon={<AddCircle />}
          onClick={() => setCreateEvent(true)}
          buttonText={'Gerar Cobrança Individual'}
        />

          <Card>
            {data?.results ?
              <>

              <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
              <Grid
                item
                xs={12}
                sm={4}
                lg={3}
              >
                <UnitFilter
                  keyName={'unit__id'}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                lg={3}
              >
                <SearchInput label={'Procurar Cobrança'} />
              </Grid>
            </Grid>
              <TableContainer>
                {data?.results?.length > 0  ?
                  <Table>
                    <TableChargeHead
                      filters={filters}
                      setFilters={setFilters}
                    />
                    <TableChargeBody
                      data={data}
                      setFilters={setFilters}
                      filters={filters}
                    />
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
              </>
              :
              <Skeleton
                variant="rounded"
                height={500}
                animation="pulse"
              />

            }
          </Card>
        <PaginationGlobal
          state={data}
          filters={filters}
          setFilters={setFilters}
        />
      </Container>
      <ChargeCreate
        createEvent={createEvent}
        setCreateEvent={setCreateEvent}
      />

    </>

  )
}

export default ChargeList;