import {
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Masks from "../../../../../../../components/Shared/Masks";
import React from "react";

const ExpenseSummaryTotal = ({
    state,
    gasWaterTotalValue
  }) => {

  return (
    <TableRow>
      <TableCell align={'right'}>
        <Typography>{'Total'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'h6'}>
          {state?.category === "DISCOUNT"  ? "-" : ''}{state?.category === 'GAS' ? Masks.money(String(gasWaterTotalValue ?? 0)) : Masks.money(String(state?.total || 0))}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default ExpenseSummaryTotal