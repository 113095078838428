import {Box, Fade, MenuItem, Skeleton, TextField} from "@mui/material";
import SimpleText from "../../../../../components/Shared/SimpleText";
import StateFilter from "../../../../../components/Filters/StateFilter";
import BlockFilter from "../../../../../components/Filters/BlockFilter";
import React from "react";
import {useParams} from "react-router-dom";

const UnitInputs =
  ({
     reqData,
     setReqData,
     error,
     isLoading,
     rentUrl,
     loadBlock,
     defaultValue,
     blockOptions,
     filters,
   }) => {
    const params = useParams()

    return (
      <Box>
        { !isLoading ?
          <TextField
            select
            required
            label={'Tipo do Imóvel'}
            value={reqData?.unit_type ?? ''}
            size={'small'}
            onChange={(e) => setReqData(prevState => ({...prevState, unit_type: e.target.value}))}
            sx={{mb: '1rem', width: '100%'}}

          >
            <MenuItem value="apartment">Apartamento</MenuItem>
            <MenuItem value="house">Casa</MenuItem>
            <MenuItem value="commercial_house">Casa comercial</MenuItem>
            <MenuItem value="pavilion">Pavilhão</MenuItem>
            <MenuItem value="commercial_room">Sala comercial</MenuItem>
          </TextField>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={39.964}
            sx={{mb: '1rem'}}
          />
        }

        <TextField
          select
          label={'Situação do Imóvel'}
          value={reqData?.status ?? ''}
          size={'small'}
          onChange={(e) => setReqData(prevState => ({...prevState, status: e.target.value}))}
          sx={{mb: '1rem', width: '100%'}}

        >
          <MenuItem value="available">Disponível</MenuItem>
          <MenuItem value="occupied">Ocupado</MenuItem>
        </TextField>

        {/*</Box>*/}


        <SimpleText
          label={'Nome do Locatário'}
          required
          state={reqData}
          setState={setReqData}
          keyName={'name'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        <SimpleText
          label={'CPF / CNPJ do Morador'}
          required
          cpfAndCnpj
          state={reqData}
          setState={setReqData}
          keyName={'cpf'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        <SimpleText
          label={'E-mail'}
          state={reqData}
          setState={setReqData}
          keyName={'email'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        <SimpleText
          label={'Telefone'}
          phone
          state={reqData}
          setState={setReqData}
          keyName={'phone'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />
        {rentUrl &&
          <SimpleText
            label={'Rua'}
            state={reqData}
            setState={setReqData}
            keyName={'street'}
            isLoading={isLoading}
            error={error}
            styles={{mb: '1rem'}}
          />
        }

        <SimpleText
          label={'Número'}
          required
          onlyNumbers
          state={reqData}
          setState={setReqData}
          keyName={'number'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        { rentUrl &&
          <>

            <SimpleText
              label={'Bairro'}
              state={reqData}
              setState={setReqData}
              keyName={'district'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'Complemento'}
              state={reqData}
              setState={setReqData}
              keyName={'complement'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <Box
              sx={{
                display: 'flex'
              }}
            >

              <SimpleText
                label={'Cidade'}
                state={reqData}
                setState={setReqData}
                keyName={'city'}
                isLoading={isLoading}
                error={error}
                styles={{
                  mr: '1rem',
                  mb: '1rem'
                }}
              />

              <StateFilter
                label={'Estado'}
                state={reqData}
                setState={setReqData}
                keyName={'state'}
                styles={{ mb: '1rem'}}
              />

            </Box>

            <SimpleText
              label={'CEP'}
              cep
              state={reqData}
              setState={setReqData}
              keyName={'cep'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />
          </>
        }



        <SimpleText
          label={'m²'}
          onlyNumbers
          state={reqData}
          setState={setReqData}
          keyName={'square_meter'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        <SimpleText
          label={'Área Construída m²'}
          onlyNumbers
          state={reqData}
          setState={setReqData}
          keyName={'building_meter'}
          isLoading={isLoading}
          error={error}
          styles={{ mb: '1rem'}}
        />

        {!rentUrl &&
          <SimpleText
            label={'Fração %'}
            decimals={true}
            state={reqData}
            setState={setReqData}
            keyName={'percent_meter'}
            isLoading={isLoading}
            error={error}
            styles={{mb: '1rem'}}
          />
        }

        {loadBlock === true && params?.id &&
          <BlockFilter
            label={'Blocos'}
            state={reqData}
            setState={setReqData}
            errors={error}
            isLoading={isLoading}
            keyName={'block'}
            defaultValue={defaultValue}
            styles={{
              mb: '1rem'
            }}
          />
        }

        {loadBlock === true && blockOptions?.length > 0 && !params?.id &&
          <BlockFilter
            label={'Blocos'}
            state={reqData}
            setState={setReqData}
            errors={error}
            isLoading={isLoading}
            keyName={'block'}
            defaultValue={defaultValue}
            styles={{
              mb: '1rem'
            }}
          />
        }

        <Fade
          in={filters?.renter}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <SimpleText
              label={'Nome do proprietário'}
              required={filters?.renter}
              state={reqData}
              setState={setReqData}
              keyName={'owner_name'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />


            <SimpleText
              label={'Telefone do proprietário'}
              phone
              state={reqData}
              setState={setReqData}
              keyName={'owner_phone'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />

            <SimpleText
              label={'E-mail do proprietário'}
              state={reqData}
              setState={setReqData}
              keyName={'owner_email'}
              isLoading={isLoading}
              error={error}
              styles={{ mb: '1rem'}}
            />
          </Box>

        </Fade>


      </Box>

    )
  }

export default UnitInputs