import GlobalSwitch from "../../../../components/Shared/GlobalSwitch";
import {
  Alert, Backdrop,
  Box,
  Button, Chip,
  Fade,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Switch, TextField,
  useMediaQuery
} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment/moment";
import {api} from "../../../../services/Main/Api";
import Context from "../../../../contexts/Context";
import {useParams} from "react-router-dom";
import Masks from "../../../../components/Shared/Masks";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonSet from "../../../../components/Shared/ButtonSet";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Close} from "@mui/icons-material";
import {useSnackbar} from "notistack";

const AutoTransfer = (
  {
    reqData,
    setReqData,
    error,
    getContract,
    prevStateData
  }
  ) => {

  const { dataProvider } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()


  const params = useParams()

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);




  function AutoTransferReq () {
    setReqLoading(true)
    const { active_date, value, bankslip_fine_value, bankslip_interest_value, contract_time } = reqData || {}

    const data = {
      ...reqData,
      active_date: moment(active_date).format('YYYY-MM-DD'),
      contract_time: +contract_time,
      value: value?.includes('R$') && value?.replace(/\D+/g, ""),
      bankslip_fine_value: bankslip_fine_value ? bankslip_fine_value?.replace(/\D+/g, "") : null,
      bankslip_interest_value: bankslip_interest_value ? bankslip_interest_value?.replace(/\D+/g, "") : null,
    }

    if (params?.id || dataProvider?.rentId) {
      api.put(`/rent_contract/${dataProvider?.rentId ?? params?.id}/`, data)
        .then(response =>{
          getContract(null, true)
          setEdit(false)
          enqueueSnackbar('Repasse automático atualizado', { variant: 'success' })
        })
        .catch(error => {
          console.log(error)
          error.response.data.transfer_day && enqueueSnackbar(error.response.data.transfer_day, { variant: 'error' })
        })
        .finally(()=> {
          setReqLoading(false)
        })
    }
  }

  function RefreshReq () {
    api.get(`rent_contract/${dataProvider?.rentId ?? params?.id}/`)
      .then(response =>{
        setReqData({...response.data, value: Masks.money(response.data.value.toString()) })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
      })
  }

  function handleAutotransfer (event, key) {
    const newValue = key === 'auto_transfer' || key === 'transfer_guaranteed' ? event.target.checked : event.target.value
    setReqData(prevState => ({
      ...prevState,
      [key]: newValue
    }))
    setButtonDisabled(prevState => ({
      ...prevState,
      [key]: newValue !== reqData?.[key]
    }))
  }

  const sizeMatch = useMediaQuery('@media (min-width:730px)')

  // function oneCondition () {
  //   const {auto_transfer, transfer_guaranteed, transfer_day, transfer_guaranteed_duration} = buttonDisabled
  //
  //   if (auto_transfer || transfer_guaranteed || transfer_day || transfer_guaranteed_duration){
  //     return true
  //   } else {
  //     return false
  //   }
  // }


  useEffect(function observeInputs() {
    const requiredKeys = ['auto_transfer', 'transfer_guaranteed', 'transfer_day', 'transfer_guaranteed_duration'];
    const hasChanged = requiredKeys.some(key => reqData?.[key]?.toString() !== prevStateData?.[key]?.toString())
    if(hasChanged) {
      setIsSaveEnabled(true)
    } else {
      setIsSaveEnabled(false)
    }

  }, [reqData]);

  useEffect(function noDueDate (){
   if (!reqData?.bankslip_due_day) {
      setReqData(prevState => ({
        ...prevState,
        auto_transfer: false,
        transfer_guaranteed: false,
        transfer_day: null,
        transfer_guaranteed_duration: null
      }))
   }
  }, [reqData?.bankslip_due_day])

  function statusColor(param) {
    switch (param) {
      case true:
        return 'success.main';
      case false:
        return 'error.main';
      default:
        return '- - -';
    }
  }

  function statusType(param) {
    switch (param) {
      case true:
        return 'Repasse Automático Ativo';
      case false:
        return 'Repasse Automático Inativo';
      default:
        return '- - -';
    }
  }

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={reqLoading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Alert
        severity={'info'}
        sx={{
          background: 'transparent',
          boxShadow: 'none',
          padding: 0,
          fontSize: '13px'
        }}
      >
        {'Opção disponível quando boleto possui dia de vencimento configurado'}
      </Alert>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
      {edit ?
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {/*<GlobalSwitch*/}
          {/*  label={'Repasse automático'}*/}
          {/*  disabled={!reqData?.bankslip_due_day}*/}
          {/*  state={reqData}*/}
          {/*  setState={setReqData}*/}
          {/*  keyName={'auto_transfer'}*/}
          {/*  styles={{mb: '1rem'}}*/}
          {/*/>*/}
          <FormControlLabel
            sx={{mb: '1rem'}}
            control={
              <Switch
                disabled={!reqData?.bankslip_due_day}
                checked={!!reqData?.auto_transfer}
                onChange={(event) => handleAutotransfer(event, 'auto_transfer')}
              />}
            label={'Repasse automático'}
          />


          <Fade
            in={!!reqData?.auto_transfer}
            mountOnEnter
            unmountOnExit
            timeout={500}
          >
        <span>
          <TextField
            label={'Dia'}
            size={'small'}
            required
            value={reqData?.transfer_day?.toString().replace(/\D+/g, "") ?? ''}
            onChange={(event) => handleAutotransfer(event, 'transfer_day')}
            sx={{
              maxWidth: reqData?.unit && sizeMatch ? '75px' : 'auto',
              mr: '0.5rem',
            }}

          />


          {/*<SimpleText*/}
          {/*  label={'Dia'}*/}
          {/*  onlyNumbers*/}
          {/*  required*/}
          {/*  state={reqData}*/}
          {/*  setState={setReqData}*/}
          {/*  keyName={'transfer_day'}*/}
          {/*  error={error}*/}
          {/*  styles={{*/}
          {/*    maxWidth: reqData?.unit && sizeMatch ? '75px' : 'auto',*/}
          {/*    mr: '0.5rem',*/}
          {/*  }}*/}
          {/*/>*/}
        </span>
          </Fade>

          <Fade
            in={!!reqData?.auto_transfer}
            mountOnEnter
            unmountOnExit
            timeout={500}
          >

            <Box
              sx={{
                display: 'flex',
              }}
            >

              <FormControlLabel
                sx={{mb: '1rem'}}
                control={
                  <Switch
                    checked={!!reqData?.transfer_guaranteed}
                    onChange={(event) => handleAutotransfer(event, 'transfer_guaranteed')}
                  />}
                label={'Transferência garantida'}
              />

              {/*<GlobalSwitch*/}
              {/*  label={'Transferência garantida'}*/}
              {/*  state={reqData}*/}
              {/*  setState={setReqData}*/}
              {/*  keyName={'transfer_guaranteed'}*/}
              {/*  styles={{mb: '1rem'}}*/}
              {/*/>*/}

              <Fade
                in={!!reqData?.transfer_guaranteed}
                mountOnEnter
                unmountOnExit
                timeout={500}
              >
            <span>
              <TextField
                label={'Duração'}
                size={'small'}
                required
                value={reqData?.transfer_guaranteed_duration?.toString()?.replace(/\D+/g, "") ?? ''}
                onChange={(event) => handleAutotransfer(event, 'transfer_guaranteed_duration')}
                sx={{
                  // maxWidth: reqData?.unit && sizeMatch ? '75px' : 'auto',
                  maxWidth: '200px',
                  mr: '0.5rem',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Meses
                    </InputAdornment>
                  )
                }}

              />

              {/*<SimpleText*/}
              {/*  label={'Duração'}*/}
              {/*  onlyNumbers*/}
              {/*  required*/}
              {/*  state={reqData}*/}
              {/*  setState={setReqData}*/}
              {/*  keyName={'transfer_guaranteed_duration'}*/}
              {/*  error={error}*/}
              {/*  // directHelper={}*/}
              {/*  styles={{*/}
              {/*    maxWidth: reqData?.unit && sizeMatch ? '150px' : 'auto',*/}
              {/*    mb: '1rem',*/}
              {/*  }}*/}
              {/*  InputProps={{*/}
              {/*    endAdornment: (*/}
              {/*      <InputAdornment position="end">*/}
              {/*        Meses*/}
              {/*      </InputAdornment>*/}
              {/*    )*/}
              {/*  }}*/}
              {/*/>*/}
            </span>
              </Fade>
            </Box>
          </Fade>
        </Box>
        :
        <Box
          sx={{maxWidth: '300px'}}
        >
          <SimpleTypography
            // label={'Repasse Automático'}
            labelStyles={{ fontWeight: 'bold '}}
            directValueFree={
              <Chip
                label={statusType(reqData?.auto_transfer)}
                // color={statusColor(reqData?.status)}
                variant={'outlined'}
                sx={{ borderColor: statusColor(reqData?.auto_transfer), color: statusColor(reqData?.auto_transfer)  }}
              />
            }
            // isLoading={!reqData?.auto_transfer}
            boxStyles={{ mb: '0' }}
          />

        </Box>
      }

      {reqData?.bankslip_due_day &&
        <ButtonSet
          removeDelete
          editState={edit}
          disableSave={!isSaveEnabled}
          editClick={() => {
            setEdit(true)
          }}
          saveClick={() => {
            AutoTransferReq()
          }}
          cancelClick={() => {
            getContract(null, true)
            setEdit(false)
          }}
        />
      }
      </Box>


      {/*{oneCondition() &&*/}
      {/*  <>*/}
      {/*    <Button*/}
      {/*      onClick={() => {*/}
      {/*        setButtonDisabled(false)*/}
      {/*        AutoTransferReq()*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Salvar*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      onClick={() => {*/}
      {/*        setButtonDisabled(false)*/}
      {/*        RefreshReq(dataProvider?.rentId)*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Cancelar*/}
      {/*    </Button>*/}
      {/*  </>*/}
      {/*}*/}
    </Box>
  )
}

export default AutoTransfer