import {Box, Grid} from "@mui/material";
import InvoiceDetailTitle from "./dependencies/InvoiceDetailTitle";
import InvoiceDetailStatus from "./dependencies/InvoiceDetailStatus";
import InvoiceDetailDue from "./dependencies/InvoiceDetailDue";
import InvoiceDetailDownload from "./dependencies/InvoiceDetailDownload";
import InvoiceDetailSentMail from "./dependencies/InvoiceDetailSentMail";
import Divider from "@mui/material/Divider";
import React from "react";
import InvoiceDetailGenerateSlip from "./dependencies/InvoiceDetailGenerateSlip";
import InvoiceDetailDeleteSlip from "./dependencies/InvoiceDetailDeleteSlip";

const InvoiceDetailHeader = ({ invoice, sending, sendToMail }) => {

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
      >
        <InvoiceDetailTitle
          invoice={invoice}
        />
        <InvoiceDetailStatus
          invoice={invoice}
        />
        <Grid
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          display={'flex'}
          textAlign={'right'}
        >
          <InvoiceDetailDue
            invoice={invoice}
          />
          <Box>
            <InvoiceDetailDownload
              invoice={invoice}
            />
            <InvoiceDetailSentMail
              invoice={invoice}
              sending={sending}
              sendToMail={sendToMail}
            />
            {!invoice?.bank_slip_status?.key ?
                <InvoiceDetailGenerateSlip
                  invoice={invoice}
                />
                :
                <InvoiceDetailDeleteSlip
                  invoice={invoice}
                />
            }

          </Box>
        </Grid>
      </Grid>
      <Divider
        sx={{
          mb: '2rem',
          mt: '.5rem'
        }}
      />
    </>
  )
}

export default InvoiceDetailHeader