import {
  Avatar,
  Box, Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Typography
} from "@mui/material";
import {Delete, Edit, Lock, Receipt} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import success from "../../../assets/animations/congratulation.json";
import { api } from "../../../services/Main/Api";
import SimpleText from "../../../components/Shared/SimpleText";
import {DatePicker} from "@mui/x-date-pickers";
import StateFilter from "../../../components/Filters/StateFilter";
import {useSnackbar} from "notistack";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {useParams} from "react-router-dom";
import CredPagoInputs from "./RentWarranty/CredPagoInputs";
import GuarantorInputs from "./RentWarranty/GuarantorInputs";
import BailInsuranceInpunts from "./RentWarranty/BailInsuranceInpunts";
import CredPagoRead from "./RentWarranty/CredPagoRead";
import GuarantorRead from "./RentWarranty/GuarantorRead";
import BailInsuranceRead from "./RentWarranty/BailInsuranceRead";
import Context from "../../../contexts/Context";
import CapitalizationInputs from "./RentWarranty/CapitalizationInputs";
import CapitalizationRead from "./RentWarranty/CapitalizationRead";
import InsuranceCard from "./RentWarranty/InsuranceCard";
import GuaranteeCard from "./RentWarranty/GuaranteeCard";
import InsuranceFormSet from "./RentWarranty/InsuranceFormSet";

const  RentWarranty = (props) => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const {
    rentData,
    guarantee,
    guaranteeData,
    setGuaranteeData,
    setGuarantee,
    isLoading,
    getContract,
    getInsurance,
    insuranceData,
    setInsuranceData,
    update,
    setIsLoading,
    show,
    setShow,
    prevWarranty
  } = props

  const { dataProvider, setDataProvider } = useContext(Context)
  // const { insurance,  guarantee } = rentData || {}
  const {enqueueSnackbar} = useSnackbar()
  const params = useParams()

  const [openDelete, setOpenDelete] = useState(false)
  const [done, setDone] = useState(false)
  const [edit, setEdit] = useState(false)
  const [guaranteSave, setGuaranteSave] = useState(false);

  //////////////////////
  //// Requisitions ////
  //////////////////////

  ////-> Delete
  async function deleteGuarantee() {
    if (guaranteeData?.id) {
      let url;

      if (guarantee === 'credpago') {
        url = `rent_contract/cred_paid/${guaranteeData?.id}/`;
      } else if (guarantee === 'guarantor') {
        url = `rent_contract/guarantor/${guaranteeData?.id}/`;
      } else if (guarantee === 'bail_insurance') {
        url = `rent_contract/bail_insurance/${guaranteeData?.id}/`;
      } else if (guarantee === 'titulo_de_captalização') {
        url = `rent_contract/capitalization_bond/${guaranteeData?.id}/`;
      } else if (guarantee === 'deposit') {
        url = `rent_contract/deposit/${guaranteeData?.id}/`;
      }


      try {
        await api.delete(url);
        enqueueSnackbar('Garantia deletada', { variant: 'success' });
        setGuaranteeData(null);
        setGuarantee(null);
        setDone(true);
        setOpenDelete(false);
        setDataProvider(prevState => ({ ...prevState, skelAuth: true }));
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Erro ao excluir garantia', { variant: 'error' });
      }
    }
  }


  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function credPagoDate(){
    if(guarantee === 'credpago' && !params.id) {
      setGuaranteeData(prevState => ({
        ...prevState,
        due_date: moment().format('YYYY-MM-DD')
      }))
    }
  }, [guarantee])

  useEffect(function afterDelete(){
    if (done) {
      update(true, false, true)
      setDone(false)
    }
  },[done])


  useEffect(function observeInputs() {
    const requiredKeys = [
      'insurance_policy_number',
      'due_date',
      'value',
      'name',
      'description',
      'cpf_cnpj',
      'phone',
      'street',
      'number',
      'district',
      'complement',
      'city',
      'state',
      'cep',
      'insurance_company',
      'installments',
      'insurer',
      'how_many_times_the_rent',
    ];
    const hasChanged = requiredKeys.some(key => guaranteeData?.[key]?.toString() !== prevWarranty?.[key]?.toString())

    if(hasChanged) {
      setGuaranteSave(true)
    } else {
      setGuaranteSave(false)
    }

  }, [guaranteeData]);


  useEffect(function observerUpdate(){
    setEdit(false)
  },[dataProvider?.successObserver])


  return (
    <>
      <GuaranteeCard
        isLoading={isLoading}
        guarantee={guarantee}
        setGuarantee={setGuarantee}
        guaranteeData={guaranteeData}
        setGuaranteeData={setGuaranteeData}
        edit={edit}
        setEdit={setEdit}
        update={update}
        guaranteSave={guaranteSave}
        getContract={getContract}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        deleteGuarantee={deleteGuarantee}
      />

      <InsuranceFormSet
        data={insuranceData}
        onChange={setInsuranceData}
        isLoading={isLoading}
        getContract={getContract}
        getInsurance={getInsurance}
        update={update}
      />


    </>
  )
}

export default RentWarranty