import {Box, Grid, Skeleton, TableBody, TableRow, Typography} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Masks from "../../../../../../../components/Shared/Masks";
import React from "react";

const InvoiceDetailTbody = ({ invoice }) => {
  return (
    <TableBody>
      {invoice?.fees?.map((row) => (
        <TableRow
          key={row.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell
            component="th"
            scope="row"
          >
            {row.description ? <> {row.description} </> :
              <>
                <Skeleton
                  variant="text"
                  animation="pulse"
                  width={100}
                  height={20}
                />
              </>}
          </TableCell>
          <TableCell
            align="right"
          >
            {row.category.key === 'GAS' ?
              <Grid
                container
                spacing={0}
              >
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    textAlign: 'center',
                    display: {sm: "none", md: "block"}}}>
                  <small>
                    {row?.category?.data?.previous_reading_date}
                  </small>

                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    textAlign: 'center',
                    display: {sm: "none", md: "block"}}}
                >
                  <small>
                    {row?.category?.data?.current_reading_date}
                  </small>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={4}
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <small>
                    Consumo
                  </small>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    textAlign: 'center',
                    display: {sm: "none", md: "block"}}
                  }>
                  {row?.category?.data?.previous_reading_amount}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{
                    textAlign: 'center',
                    display: {sm: "none", md: "block"}}}
                >
                  {row?.category?.data?.current_reading_amount}
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={4}
                  sx={{textAlign: 'center'}}
                >
                  {row?.category?.data?.consumed}
                </Grid>
              </Grid>

              :
              <>
                {row.category.key !== 'DISCOUNT' ?
                  Masks.money(String(row?.condo_total))
                  :
                   `- ${Masks.money(String(row?.condo_total))}`
                }
              </>
            }
          </TableCell>
          <TableCell align="right" >
            {Masks.money(String(row?.total))}
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell align="right"/>
        <TableCell align="right"/>
        <TableCell align="right">
          { invoice?.total_interest_and_fine ? <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant={'subtitle2'} fontSize={"large"} mr={2}>Total:</Typography>
            <Typography variant={'subtitle2'} fontSize={"large"} >{Masks.money(String(invoice?.total))}</Typography>
          </Box> :
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Typography variant={'h6'}  mr={2}>Total:</Typography>
              <Typography variant={'h5'} fontWeight={'bolder'} color={'primary'}>{Masks.money(String(invoice?.total))}</Typography>
            </Box> }
        </TableCell>

      </TableRow>
      { invoice?.total_interest_and_fine && <TableRow>
        <TableCell align="right"/>
        <TableCell align="right"/>
        <TableCell align="right">
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant={'subtitle2'} fontSize={"medium"} mr={2}>Multa e juros:</Typography>
            <Typography variant={'subtitle2'} fontSize={"medium"}>{Masks.money(String(invoice?.total_interest_and_fine))}</Typography>
          </Box>
        </TableCell>

      </TableRow> }
      { invoice?.total_interest_and_fine && <TableRow>
        <TableCell align="right"/>
        <TableCell align="right"/>
        <TableCell align="right">
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Typography variant={'h6'} mr={2}>Total Pago:</Typography>
            <Typography variant={'h5'} fontWeight={'bolder'} color={'primary'}>{Masks.money(String(invoice?.total_paid))}</Typography>
          </Box>
        </TableCell>

      </TableRow> }
    </TableBody>
  )
}

export default InvoiceDetailTbody