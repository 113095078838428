import {
  Alert,
  Avatar, Box, Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Skeleton, Typography,
} from "@mui/material";
import {Edit, Receipt} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SlipOptionsInputs from "./SlipOptionsInputs";
import SlipOptionsRead from "./SlipOptionsRead";
import Context from "../../../../contexts/Context";
import SaveCancelButton from "../../../../components/Shared/SaveCancelButton";

const RentSlipOptions = (props) => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const {
    reqData,
    setReqData,
    isLoading,
    error,
    getContract,
    update,
    prevStateData
  } = props

  const { dataProvider } = useContext(Context)

  const {bankslip_fine_value, bankslip_interest_value, generate_bankslip, generate_bankslip_day,bankslip_fine_percent } = reqData || {}

  const params = useParams()
  const [bankFine, setBankFine] = useState(bankslip_fine_value ? 'value' : 'percent');
  const [bankInterest, setBankInterest] = useState(bankslip_interest_value ? 'value' : 'percent');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const [edit, setEdit] = useState(false)

  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function handleBankFineChange (event) {
    const value = event.target.value;
    setBankFine(value);
    setReqData(prevState => {
      const updatedData = { ...prevState };
      if (value === 'value') {
        updatedData.bankslip_fine_percent = null;
      } else {
        updatedData.bankslip_fine_value = null;
      }
      return updatedData;
    });
  };

  function handleBankInterestChange (event) {
    const value = event.target.value;
    setBankInterest(value);
    setReqData(prevState => {
      const updatedData = { ...prevState };
      if (value === 'value') {
        updatedData.bankslip_interest_percent = null;
      } else {
        updatedData.bankslip_interest_value = null;
      }
      return updatedData;
    });
  }

  function errorHandling() {
    const errorMessages = [
      "Para gerar boletos automaticos, verifique se o dia de geração dos boletos está definido",
      "O dia de geração do boleto não pode ser igual ou maior que o dia de vencimento do boleto",
    ];

    if (Array.isArray(error)) {
      const matchedErrors = errorMessages.filter(message => error.includes(message));
      return matchedErrors.length > 0;
    }
    if (error?.generate_bankslip_day){
      return true
    }

    return false;
  }


  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function generate_bankslipObserver(){
    if (!generate_bankslip) {
      setReqData(prevState => ({...prevState, generate_bankslip_day: null}))
    }
  },[generate_bankslip])

  useEffect(function setNull (){
    if (generate_bankslip_day === "") {
      setReqData(prevState => ({...prevState, generate_bankslip_day: null}))
    }
  }, [generate_bankslip_day])

  useEffect(function errorObserver() {
    errorHandling()
  },[error])

  useEffect(function observeInputs() {
    const requiredKeys = [
      'generate_bankslip',
      'generate_bankslip_day',
      'bankslip_fine_value',
      'bankslip_fine_percent',
      'bankslip_interest_value',
      'bankslip_interest_percent',
      'bankslip_due_day',
      'send_email'
    ]
    const hasChanged = requiredKeys.some(key => reqData?.[key]?.toString() !== prevStateData?.[key]?.toString())
    if(hasChanged) {
      setIsSaveEnabled(true)
    } else {
      setIsSaveEnabled(false)
    }

  }, [reqData])

  // useEffect(function ifSomeValueEmprty(){
  //   if(bankslip_fine_value?.replace(/\D+/g, "") > 0){
  //     setReqData(prevState => ({...prevState, bankslip_fine_value: null}))
  //   }
  //   if(bankslip_fine_percent > 0){
  //     setReqData(prevState => ({...prevState, bankslip_fine_value: null}))
  //   }
  // },[reqData])

  useEffect(() => {
    setBankFine(bankslip_fine_value ? 'value' : 'percent')
    setBankInterest(bankslip_interest_value ? 'value' : 'percent')
  }, [edit])

  useEffect(function (){
    setEdit(false)
  },[dataProvider?.successObserver])


  return (
    <>
      {!isLoading ?
        <Card>
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor: 'primary.main'
                }}>
                <Receipt/>
              </Avatar>}
            title={'Boleto'}
          />
          <Divider sx={{ mb: '.3rem' }}/>

          <CardContent>

            {!params?.id &&
              //// => Title in create
              <Box
                sx={{
                  // paddingX: '1rem'

                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: 'primary.main',
                  }}
                >
                  Configurações
                </Typography>
                {/*<Divider*/}
                {/*  sx={{*/}
                {/*    mt: '0.7rem',*/}
                {/*    mb: '1rem',*/}
                {/*  }}*/}
                {/*/>*/}
              </Box>
              ////
            }


            {params?.id &&
              //// => Title and buttons in update
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  // paddingX: '1rem'

                }}
              >
                <Typography
                  sx={{
                    // ...(edit && {mb: '1rem'}),
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: 'primary.main',

                  }}>
                  Configurações
                </Typography>
                {!edit &&
                  <IconButton
                    sx={{p: 0}}
                    onClick={() => setEdit(true)}
                  >
                    <Edit/>
                  </IconButton>
                }

                <SaveCancelButton
                  edit={edit}
                  align={'start'}
                  disabled={!isSaveEnabled}
                  saveClick={() => {
                    update()
                  }}
                  cancelClick={() => {
                    getContract(null, true)
                    setEdit(false)
                  }}
                />


                {/*{edit &&*/}
                {/*  <Box>*/}
                {/*    <Button*/}
                {/*      size={'small'}*/}
                {/*      disabled={!isSaveEnabled}*/}
                {/*      variant={'contained'}*/}
                {/*      onClick={() => {*/}
                {/*        update()*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      Salvar*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*      size={'small'}*/}
                {/*      onClick={() => {*/}
                {/*        getContract(null, true)*/}
                {/*        setEdit(false)*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      Cancelar*/}
                {/*    </Button>*/}
                {/*  </Box>*/}
                {/*  ////*/}
                {/*}*/}
              </Box>
            }
            {params?.id &&
              //// => Content for contract
              <Box
                sx={{
                  // paddingX: '1rem'
                }}
              >
                {/*<Divider*/}
                {/*  sx={{*/}
                {/*    mb: '1rem',*/}
                {/*    ...(edit && {mt: '0.6rem'})*/}
                {/*  }}*/}
                {/*/>*/}
              </Box>
            }

            {edit || !params?.id ?
              <SlipOptionsInputs
                reqData={reqData}
                setReqData={setReqData}
                bankFine={bankFine}
                bankInterest={bankInterest}
                error={error}
                errorHandling={errorHandling}
                handleBankFineChange={handleBankFineChange}
                handleBankInterestChange={handleBankInterestChange}
              />
              :
              <SlipOptionsRead
                reqData={reqData}
              />
            }

            <Alert
              severity={'info'}
              sx={{
                background: 'transparent',
                boxShadow: 'none',
                mt: '1rem',
                padding: 0
              }}
            >
              {'Caso não preenchido, são válidas as informações fornecidas em "Configurações"'}
            </Alert>

            {/*{params?.id && isSaveEnabled &&*/}
            {/*  <Box*/}
            {/*    sx={{*/}
            {/*      display: 'flex',*/}
            {/*      alignItems: 'center',*/}
            {/*      justifyContent: 'center'*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Button*/}
            {/*      sx={{*/}
            {/*        mr: '0.5rem'*/}
            {/*      }}*/}
            {/*      onClick={() => getContract(null, true)}*/}
            {/*    >*/}
            {/*      Cancelar*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*      variant={'contained'}*/}
            {/*      onClick={() => update()}*/}
            {/*    >*/}
            {/*      Salvar*/}
            {/*    </Button>*/}

            {/*  </Box>*/}
            {/*}*/}

          </CardContent>
        </Card>
        :
        <Skeleton
          height={400}
          width={'100%'}
          variant='rounded'
        />
      }

    </>
  )
}

export default RentSlipOptions