import {IconButton, Tooltip} from "@mui/material";
import {ForwardToInbox, ForwardToInboxOutlined, Pending} from "@mui/icons-material";
import React from "react";

const InvoiceDetailSentMail = ({ invoice, sending, sendToMail }) => {
  return (
    <Tooltip
      title={'Enviar boleto por email'}
      arrow
      placement={'top'}>
      { !sending ?
        <span>
          <IconButton
            disabled={invoice?.bank_slip.length > 0 ? false : true}
            onClick={sendToMail}
          >
            <ForwardToInbox/>
          </IconButton>
        </span>
        :
        <span>
          <IconButton
            disabled
          >
            <Pending/>
          </IconButton>
        </span>
      }
    </Tooltip>
  )
}

export default InvoiceDetailSentMail