import React, {useContext, useEffect, useState} from 'react'
import Context from '../../../contexts/Context'
import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
} from '@mui/material'
import {
  gasWaterPreviousReading,
  gasWaterValue,
  getCategories,
  getDocuments
} from "./Requisitions";
import {
  getCondoId,
  setFilterDate
} from '../../../services/Main/AuthStorage'
import {
  useNavigate,
  useParams
} from 'react-router-dom'
import { api } from '../../../services/Main/Api'
import { Helmet } from 'react-helmet'
import { useSnackbar } from "notistack";
import GasConsume from "./components/ExpenseCreateUpdate/GasConsume/GasConsume";
import moment from "moment/moment";
import ExpenseInfo from "./components/ExpenseCreateUpdate/ExpenseInfo/ExpenseInfo";
import ExpensePayment from "./components/ExpenseCreateUpdate/ExpensePayment/ExpensePayment";
import ExpenseResume from "./components/ExpenseCreateUpdate/ExpenseResume/ExpenseResume";
import ExpenseDocuments from "./components/ExpenseCreateUpdate/ExpenseDocuments/ExpenseDocuments";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import WaterConsume from "./components/ExpenseCreateUpdate/WaterConsume/WaterConsume";

const ExpenseUpdate = () => {
  const {
    userFiles,
    deleteApiFiles,
    setDeleteApiFiles,
    condoIdLogin,
    setDocuments,
    loadingModal,
    setLoadingModal,
    unitOptions,
    unitFormat,
    filters
  } = useContext(Context)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const params = useParams()

  const [reqData, setReqData] = useState(null)
  const [showGas, setShowGas] = useState(false)
  const [gasWaterRequired, setGasWaterRequired] = useState(false)
  const [errors, setErrors] = useState()
  const [installments, setInstallments] = useState(1)
  const [gasWaterTotalValue, setGasWaterTotalValue] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [previousData, setPreviousData] = useState(null)
  const [dropLoad, setDropLoad] = useState(true)


  const checkCategory = async () => {
    try {
      const response = await api.get(`/expense/${params.id}`);
      const { category } = response.data;

      let categoryEndpoint = '';

      if (category.key === 'WATER_READING') {
        categoryEndpoint = 'water';
      } else if (category.key === 'GAS') {
        categoryEndpoint = 'gas';
      }

      if (categoryEndpoint) {
        const categoryResponse = await api.get(`expense/${categoryEndpoint}/${params.id}`);
        setReqData({
          ...categoryResponse.data,
          division_pattern: categoryResponse.data.division_pattern.key,
          category: categoryResponse.data.category.key
        });
      } else {
        setReqData({
          ...response.data,
          division_pattern: response.data.division_pattern.key,
          category: response.data.category.key
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors(null)
    setLoadingModal(true)
    const fullDate = moment(`${reqData?.billing_year}-${reqData?.billing_month}-01`, 'YYYY-MM-DD').format()
    setFilterDate(fullDate)

    const data = {
      ...reqData,
      total: reqData?.category !== 'DISCOUNT' ? reqData?.total?.toString()?.replace(/\D+/g, "")  : reqData?.total?.toString()?.replace(/[^0-9.-]+/g, '')  * -1,
      previous_reading_amount: reqData?.previous_reading_amount?.toString().replace(/,/g,'.') || 0,
      current_reading_amount: reqData?.current_reading_amount?.toString().replace(/,/g,'.'),
      ...(!reqData?.exempt_units && {exempt_units: []}),
      condo: getCondoId()
    }


    let expenseUrl = `expense/${params.id}/`
    if (reqData?.category === 'GAS') {
      expenseUrl = `expense/gas/${params.id}/`
    }

    if (reqData?.category === 'WATER') {
      expenseUrl = `expense/${params.id}/`
    }

    api.put(expenseUrl, data).then(r => {
      userFiles?.length > 0 &&
        userFiles?.map(file =>
          api.post('expense/document/',
            {
              expense: params?.id,
              file: file,
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(r => {

            }).catch(error => {
              console.log(error.response.data)
            }))
      deleteApiFiles?.length > 0 &&
        deleteApiFiles?.map(file =>
          api.delete(`expense/document/${file.id}`,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
            ).then(r => {
            }).catch(error => {
              console.log(error)
            }))
        setDeleteApiFiles([])
      enqueueSnackbar('Despesa atualizada com sucesso!', { variant: 'success' })
      navigate('/despesas', { replace: true })
    }).catch(reason => {
      console.log(reason)
      enqueueSnackbar('Erro ao atualizar despesa!', { variant: 'error' })
      setErrors(reason?.response.data)
    })
      .finally(()=>{
        setLoadingModal(false)
      })
  }


  useEffect(function getOptions(){
    getCategories(setCategoryList, condoIdLogin)
    checkCategory()
    getDocuments(setDocuments, params, setDropLoad, filters)
    unitFormat()
  }, [])


  useEffect(function categoryOptions(){
    setCategoryOptions(
      categoryList.reduce(function(result, current) {
        return Object.assign(result, current);
      }, {})
    )
  }, [categoryList])

  useEffect(function getGasValue() {
    gasWaterValue(reqData, setGasWaterTotalValue)
  }, [reqData?.current_reading_amount])

  useEffect(function clearHelperText(){
    setPreviousData(null)
  }, [reqData?.division_pattern, reqData?.unit])

  useEffect(function previousDateAndReading (){
    gasWaterPreviousReading(reqData, setPreviousData)
  },[reqData?.unit, reqData?.category])



  return (
    <>
      <Helmet>
        <title>Editar Despesa - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                { reqData ?
                <ExpenseInfo
                  title={'Editar Despesa'}
                  reqData={reqData}
                  setReqData={setReqData}
                  setShowGas={setShowGas}
                  setGasWaterRequired={setGasWaterRequired}
                  categoryOptions={categoryOptions}
                  unitOptions={unitOptions}
                  errors={errors}
                  disabled={true}
                />
                :
                  <Grid item xs={12}>
                    <Skeleton
                      height={400}
                      width={'100%'}
                      variant='rounded'
                      />
                  </Grid>
                }
                {showGas &&
                <>
                  {reqData?.current_reading_amount ?
                    <GasConsume
                      state={reqData}
                      setState={setReqData}
                      showGas={showGas}
                      previousData={previousData}
                      gasWaterRequired={gasWaterRequired}
                      errors={errors}
                    />
                    :
                    <Grid item xs={12}>
                      <Skeleton
                        height={300}
                        width={'100%'}
                        variant='rounded'
                      />
                    </Grid>
                  }
                </>
                }

                {reqData ?
                  <WaterConsume
                    state={reqData}
                    setState={setReqData}
                    previousData={previousData}
                    gasWaterRequired={gasWaterRequired}
                    errors={errors}
                  />
                  :
                  <Grid item xs={12}>
                    <Skeleton
                      height={300}
                      width={'100%'}
                      variant='rounded'
                    />
                  </Grid>
                }



                {reqData ?
                  <ExpensePayment
                    reqData={reqData}
                    setReqData={setReqData}
                    gasWaterRequired={gasWaterRequired}
                    installments={installments}
                    setInstallments={setInstallments}
                    errors={errors}
                  />
                    :
                  <Grid item xs={12}>
                    <Skeleton
                      height={300}
                      width={'100%'}
                      variant='rounded'
                    />
                  </Grid>
                }


              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>


              {reqData ?
              <ExpenseResume
                reqData={reqData}
                gasWaterTotalValue={gasWaterTotalValue}
                installments={installments}
              />
              :
              <Grid item xs={12}>
                <Skeleton
                  height={230}
                  width={'100%'}
                  variant='rounded'
                />
              </Grid>
              }

              <Grid item xs={12}>
                {reqData ?
                  <ExpenseDocuments />
                  :
                  <Skeleton
                    height={200}
                    width={'100%'}
                    variant='rounded'
                    // sx={{ marginTop: '2rem' }}
                  />
                }
              </Grid>

              </Grid>
            </Grid>
          </Grid>
          <SimpleButtons
            forwardButton={'Salvar'}
            type={'submit'}
            forwardDisable={!(!!reqData)}
          />

        </>
      </Container>
    </>
  )
}

export default ExpenseUpdate
