import React, { createRef, useContext, useEffect, useState } from 'react'
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container, Grid, IconButton, Skeleton,
  Snackbar, Tab,
  Tabs,
  TextField, Typography
} from '@mui/material'
import Context from '../../../contexts/Context'
import { api } from '../../../services/Main/Api'
import {
  AccountBoxOutlined,
  ArrowBack, BrandingWatermark,
  LockClockOutlined,
  PersonOutlineOutlined,
  PhotoCamera
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import { useSnackbar } from 'notistack'
import ChangePassword from './ChangePassword'
import { grey } from '@mui/material/colors'
import { Helmet } from 'react-helmet'
import {
  localUnitUser,
  removeCondoId,
  removeCondoName, removeFilterDate,
  removeLocalUser,
  setLocalUser
} from "../../../services/Main/AuthStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {apiUser} from "../../../services/UnitUser/ApiUnitUser";
import Branding from "./Branding";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import ButtonSet from "../../../components/Shared/ButtonSet";
import {removeTokens} from "../../../services/Main/AuthToken";
import {removeTokensUnitUser} from "../../../services/UnitUser/UnitUserToken";

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-profile-${index}`}
      aria-labelledby={`user-profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps (index) {
  return {
    id: `user-profile-tab-${index}`,
    'aria-controls': `user-profile-${index}`,
  }
}

const ProfileUpdate = () => {
  const location = useLocation()
  const { loadingModal, setLoadingModal, setUser } = useContext(Context)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [imageFile, setImageFile] = useState()
  const [errors, setErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [image, _setImage] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [isContentLoading, setIsContentLoading] = useState(true)
  const [path, setPath] = useState(null)
  const [edit, setEdit] = useState(false)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const cleanup = () => {
    URL.revokeObjectURL(image)
  }

  const navigate = useNavigate()

  const setImage = (newImage) => {
    if (image) {
      cleanup()
    }
    _setImage(newImage)
  }

  const handlePictureChange = (e) => {
    const newImage = e.target?.files?.[0]
    setImageFile(newImage)
    if (newImage) {
      setImage(URL.createObjectURL(newImage))
    }
  }
  useEffect(function whatPath(){
    const pathName = location.pathname.includes('/usuario/perfil')
    setPath(!pathName ? api.get('user/profile/') : apiUser.get('user/profile/') )
  }, [])

  function getProfile() {
    if (path) {
      path
        .then(response => {
          if (response.status === 200) {
            setFirstName(response?.data.first_name)
            setLastName(response?.data.last_name)
            setEmail(response?.data.email ?? localUnitUser().email)
            setImage(response?.data.photo)
          }
        }).catch(response => {
        console.log(response)
        removeTokens()
        removeLocalUser()
        removeCondoId()
        removeCondoName()
        removeFilterDate()
        removeTokensUnitUser()
        navigate('/login')
      }).finally(() => {
        setIsContentLoading(false)
      })
    }
  }

  useEffect(() => {
    getProfile()
  }, [path])

  const handleSubmit = () => {
    setLoadingModal(true)
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      photo: imageFile
    }

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const pathName = location.pathname.includes('/usuario/perfil')

    !pathName ? api.put('user/profile/', data, headers) : apiUser.put('user/profile/', data, headers)

      if(!pathName) {
      api.put('user/profile/', data, headers)
      .then(response => {
      if (response.status === 200) {
        setEdit(false)
        setUser(response?.data)
        setLocalUser(response?.data)
        setErrors(null)
        enqueueSnackbar('Perfil atualizado com sucesso!', { variant: 'success' })
      }
    }).catch(response => {
      setErrors(response?.response.data)
    }).finally(() => {
        setLoadingModal(false)
      })

       } else {
        apiUser.put('user/profile/', data, headers)
          .then(response => {
            if (response.status === 200) {
              setUser(response?.data)
              setLocalUser(response?.data)
              setErrors(null)
              enqueueSnackbar('Perfil atualizado com sucesso!', { variant: 'success' })
            }
          }).catch(response => {
          setErrors(response?.response.data)
        }).finally(() => {
          setLoadingModal(false)
        })
      }
  }



  function getInitials() {
    const fullname = firstName + ' ' + lastName

    return fullname.split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();
  }

  return (
    <>
      <Helmet>
        <title>Atualizar Perfil - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container>
        {isContentLoading ?
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
              <Skeleton variant="rectangular" animation="pulse" width={200} height={40}/>
              <Skeleton variant="rectangular" animation="pulse" width={200} height={40}/>
            </Box>
            <Skeleton variant="rectangular" animation="pulse" width={100} height={40} sx={{ my: 3 }}/>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <>
                  <Skeleton variant="rounded" height={400}/>
                </>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Skeleton variant="rounded" height={400}/>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 4 }}>
              <Skeleton variant="rectangular" animation="pulse" width={100} height={40}/>
              <Skeleton variant="rectangular" animation="pulse" width={100} height={40}/>
            </Box>
          </>
          : <Box>
            <Box component={'div'}
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant={'h5'}>{'Dados do Usuário'}</Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Tabs sx={{ minHeight: '36px' }} value={tabValue} onChange={handleTabChange}
                    aria-label="Perfil do Usuário">
                <Tab sx={{ pl: 0, mr: 3, minHeight: 0 }}
                      icon={<AccountBoxOutlined/>}
                      iconPosition={'start'}
                      label="Geral" {...a11yProps(0)} disableRipple/>
                <Tab sx={{pl: 0, minHeight: 0}}
                      icon={<LockClockOutlined/>}
                      iconPosition={"start"}
                      label="Alterar Senha" {...a11yProps(1)} disableRipple />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Box autoComplete="off" >
                <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                  <Grid item xs={12} lg={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardHeader title={'Minha Foto'}/>
                      <Divider light sx={{ mb: 1 }}/>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3, pb: 1, position: 'relative' }}>
                          {image ?
                            <Avatar
                              sx={(theme) => ({
                                width: theme.spacing(18),
                                height: theme.spacing(18),
                                borderWidth: "1px",
                                borderStyle: 'dashed',
                                borderColor: grey[300],
                                p: 1
                              })} src={image}>
                              <PhotoCamera/>
                            </Avatar>
                            :
                            <Avatar
                              sx={(theme) => ({
                                backgroundColor: theme.palette.secondary.main,
                                width: theme.spacing(18),
                                height: theme.spacing(18),
                                borderWidth: "1px",
                                fontSize: '4.5rem',
                                borderStyle: 'dashed',
                                borderColor: grey[300],
                                p: 1
                              })}>
                              {getInitials()}
                            </Avatar>
                          }
                        </Box>
                        <Box display={'flex'} justifyContent={'center'}>
                          <IconButton aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" id={'profile_picture'}
                                    onChange={handlePictureChange}/>
                            <PhotoCamera/>
                          </IconButton>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Card sx={{ height: '100%' }}>
                      <CardHeader
                        title={'Dados Pessoais'}
                        action={
                          <ButtonSet
                            editState={edit}
                            editClick={() => {setEdit(true)}}
                            removeDelete
                            saveClick={() => {handleSubmit()}}
                            cancelClick={() => {
                              getProfile()
                              setEdit(false)
                            }}
                          />
                        }
                      />
                      <Divider light sx={{ mb: 1 }}/>
                      <CardContent>
                        <Box component={'div'} sx={{ mb: 3 }}>


                          {!edit ?
                            <SimpleTypography
                              label={'Nome'}
                              size={'small'}
                              directValue={firstName}
                              isLoading={!firstName}
                              labelStyles={{
                                fontWeight: 'bold'
                              }}
                              boxStyles={{
                                width: '100%',
                                mb: '0.5rem'
                              }}
                            />
                            : edit &&
                            <TextField
                              fullWidth
                              size={'small'}
                              label={'Nome'}
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value)
                              }}
                              error={!!errors?.last_name}
                              helperText={errors?.last_name ? errors.last_name[0] : ''}
                            />
                          }


                        </Box>
                        <Box component={'div'} sx={{ mb: 3 }}>

                          {!edit ?
                            <SimpleTypography
                              label={'Sobrenome'}
                              directValue={lastName}
                              isLoading={!lastName}
                              labelStyles={{
                                fontWeight: 'bold'
                              }}
                              boxStyles={{
                                width: '100%',
                                mb: '0.5rem'
                              }}
                            />
                            : edit &&
                            <TextField
                              fullWidth
                              size={'small'}
                              label={'Sobrenome'}
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value)
                              }}
                              error={!!errors?.last_name}
                              helperText={errors?.last_name ? errors.last_name[0] : ''}
                            />
                          }


                        </Box>
                        <Box component={'div'} sx={{ mb: 0}}>

                          {!edit ?
                            <SimpleTypography
                              label={'E-mail'}
                              directValue={email}
                              isLoading={!email}
                              labelStyles={{
                                fontWeight: 'bold'
                              }}
                              boxStyles={{
                                width: '100%',
                              }}
                            />
                            : edit &&
                              <TextField
                                size={'small'}
                                fullWidth
                                label={'Email'}
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value)
                                }}
                                type={'email'}
                                disabled
                              />
                          }

                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Box component={'div'}
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
                  <Button type={'button'} variant={'outlined'} onClick={(e) => {
                    window.history.back()
                  }} startIcon={<ArrowBack/>}>{'Voltar'}</Button>
                  <Button
                    variant={'contained'}
                    type={'submit'}
                    onClick={handleSubmit}
                  >
                    {'Salvar'}
                  </Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ChangePassword/>
            </TabPanel>
          </Box>
        }
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
      </Container>
    </>
  )
}
export default ProfileUpdate
