import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import moment from "moment";
import Masks from "../../../../components/Shared/Masks";
import {Box} from "@mui/material";

const CredPagoRead =
  ({
    edit,
    guarantee,
    guaranteeData,
   }) => {

  return (
    <>
      {guarantee === 'credpago' && !edit &&
        <Box>
          {guaranteeData?.insurance_policy_number &&
            <SimpleTypography
              label={'Número de Apólice/Contrato'}
              directValue={guaranteeData?.insurance_policy_number}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.due_date &&
            <SimpleTypography
              label={'Data de Vencimento'}
              directValue={moment(guaranteeData?.due_date).format('DD/MM/YYYY')}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          { guaranteeData?.value &&
            <SimpleTypography
              label={'Valor'}
              directValue={Masks?.money(guaranteeData?.value?.toString() ?? '') ?? ''}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

        </Box>
      }
    </>
  )
}

export default CredPagoRead