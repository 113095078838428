import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Divider from '@mui/material/Divider'
import StatusInvoice from '../../../components/Invoice/StatusInvoice'
import {getCondoId} from '../../../services/Main/AuthStorage'
import InternalNotesCard from '../../../components/Shared/InternalNotesCard'
import {ArrowBack, DescriptionOutlined, ForwardToInboxOutlined, PhoneOutlined, Pending} from '@mui/icons-material'
import Masks from "../../../components/Shared/Masks"
import moment from 'moment'
import {Helmet} from 'react-helmet'
import {useSnackbar} from "notistack";
import {apiUser} from "../../../services/UnitUser/ApiUnitUser";

const UnitUserInvoiceDetail = () => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [invoice, setInvoice] = useState()
  const [condo, setCondo] = useState()
  const [invoiceList, setInvoiceList] = useState('')
  const [routeId, setRouteId] = useState('')
  const [sending,  setSending] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setIsLoading(true)
    getInvoiceId()
    apiUser.get(`invoice/`)
      .then(response => {
        const data = response.data
        setInvoiceList(data.results)
        setIsLoading(false)
      }).catch(error => {
      console.log(error)
    })
    setIsLoading(false)
  }, [])


  const getInvoiceId = async () => {
    const url = `invoice/${params.id}/`
    let result = await apiUser.get(url)
    result = await result.data
    setInvoice(result)
  }

  useEffect(function changeRoute(){
    if (routeId){
      navigate(`/usuario/faturas/${routeId}/`)
      navigate(0)
    }

  }, [routeId])


  console.log(routeId)

  const sendToMail = () => {
    setSending(true)
    apiUser.post(`/bank-slip/invoice/${invoice?.id}/send-mail/`)
      .then(r => {
        enqueueSnackbar(r.data.success, {variant: "success"})
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar('Erro ao Enviar Para o E-mail', {variant: "error"})
      }).finally(() =>{
      setSending(false)
    })

  }

  return (
    <>
      <Helmet>
        <title>Detalhes da Fatura - Bloco B3</title>
      </Helmet>
      <Container fixed>
        {!isLoading ? <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent={'space-between'}>
              <Grid item xs={6}>
                <Box border sx={{ display: 'flex', alignItems: 'center' }}>
                  {invoice
                    ? <Typography variant="h6">{invoice?.number}</Typography>
                    : <Skeleton
                      variant="rectangular"
                      animation="pulse"
                      width={100}
                      height={20}/>
                  }
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box border sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <StatusInvoice status={invoice?.status}/>
                </Box>
              </Grid>
              <Grid item xs={12} justifyContent={'space-between'} alignItems={'center'} display={'flex'}
                    textAlign={'right'}>
                {!invoice?.status?.key === 'open' &&
                  <Box>
                    <Typography
                      variant={'body2'}>
                      Vencimento: {moment(invoice?.due_date).format('DD \\d\\e MMMM \\d\\e YYYY')}
                    </Typography>
                  </Box>}
                <Box>
                  <Tooltip
                    title={invoice?.bank_slip_status.key !== 5 ? 'Baixar Boleto' : 'Boleto Pago'}
                    arrow
                    placement={'top'}>
                    <span>
                      <IconButton
                        disabled={!invoice?.bank_slip.length || invoice?.bank_slip_status.key === 5}
                        target={'_blank'} href={invoice?.bank_slip.length ? invoice?.bank_slip[0].file : void (0)}>
                        <DescriptionOutlined/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={'Enviar boleto por email'} arrow placement={'top'}>
                    {
                      !sending
                        ?
                        <span>
                      <IconButton
                        disabled={invoice?.bank_slip.length > 0 ? false : true}
                        onClick={sendToMail}
                      >
                        <ForwardToInboxOutlined/>
                      </IconButton>
                    </span>
                        :
                        <span>
                        <IconButton
                          disabled
                        >
                          <Pending/>
                        </IconButton>
                      </span>



                    }
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '2rem', mt: '.5rem' }}/>
            <Grid
              container
              spacing={2}
              sx={{ paddingX: '2rem' }}
              justifyContent={'space-between'}
              alignContent={'center'}>
              <Grid item xs={12} lg={6}>
                {/*<Typography mb={1}>De:</Typography>*/}
                {invoice
                  ? <>
                    <Typography fontWeight={'bold'} mb={1} variant="body1">Imóvel: {invoice?.unit}</Typography>
                    <Typography variant="body2">Mês de Pagamento: {invoice?.reference_month}</Typography>
                    {invoice?.due_date &&
                      <Typography variant="body2">Data de Vencimento: {invoice?.due_date}</Typography>}
                    <Typography variant="body2">Situação: {invoice?.status?.value}</Typography>
                    {invoice?.paid_at &&
                      <Typography variant="body2">Pago em: {moment(invoice?.paid_at).format('DD/MM/YYYY')}</Typography>}

                  </>
                  : <>
                    <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                    <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                    <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                    <Skeleton variant="text" animation="pulse" width={200} height={20}/>
                  </>
                }
              </Grid>
            </Grid>

            <TableContainer sx={{ p: '1rem' }}>
              <Table sx={{ marginY: '2rem' }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'gray' }}>Categoria</TableCell>
                    <TableCell sx={{ color: 'gray' }}> </TableCell>
                    <TableCell align="right" sx={{ color: 'gray' }}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice?.fees?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.description ? <> {row.description} </> :
                          <>
                            <Skeleton variant="text" animation="pulse" width={100} height={20}/>
                          </>}
                      </TableCell>
                      <TableCell align="right" >
                        {row.category.key === 'GAS' ?
                          <Grid container  spacing={0}>
                            <Grid item xs={6} sm={4} sx={{textAlign: 'center', display: {sm: "none", md: "block"}}}>
                              <small>
                                {row?.category?.data?.previous_reading_date}
                              </small>

                            </Grid>
                            <Grid item xs={6} sm={4} sx={{textAlign: 'center', display: {sm: "none", md: "block"}}}>
                              <small>
                                {row?.category?.data?.current_reading_date}
                              </small>
                            </Grid>
                            <Grid item  sm={12} md={4} sx={{textAlign: 'center'}}>
                              <small>
                                Consumo
                              </small>
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{textAlign: 'center', display: {sm: "none", md: "block"}}}>
                              {row?.category?.data?.previous_reading_amount}
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{textAlign: 'center', display: {sm: "none", md: "block"}}}>
                              {row?.category?.data?.current_reading_amount}
                            </Grid>
                            <Grid item sm={12} md={4} sx={{textAlign: 'center'}}>
                              {row?.category?.data?.consumed}
                            </Grid>
                          </Grid>

                          :
                          <>
                            {row.category.key !== 'DISCOUNT' &&
                              Masks.money(String(row?.condo_total))
                            }</>
                        }
                      </TableCell>
                      <TableCell align="right" >
                        {Masks.money(String(row?.total))}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right"/>
                    <TableCell align="right"/>
                    <TableCell align="right">
                      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Typography variant={'h6'} mr={2}>Total:</Typography>
                        <Typography variant={'h5'} fontWeight={'bolder'} color={'primary'}>{Masks.money(String(invoice?.total))}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card> : ''}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: '1rem' }}>
          <Button
            type={'button'} variant={'outlined'}
            startIcon={<ArrowBack/>}
            onClick={(e) => {
              navigate('/usuario/faturas')
            }}>{'Voltar para as faturas'}</Button>
          <Button
            type="button"
            onClick={() => {
              invoiceList?.filter(e => e.id > params.id).length > 0 ? setRouteId(invoiceList?.filter(e => e.id > params.id)?.slice(-1)[0].id) : setRouteId(invoiceList?.filter(e => e.id < params.id).slice(-1)[0].id)
            }


            }
            variant="contained"
            size="large">
            {'Próxima fatura'}
          </Button>
        </Box>
      </Container>
      <InternalNotesCard state={invoice?.internal_notes}/>
    </>
  )
}

export default UnitUserInvoiceDetail
