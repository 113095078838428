import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {Box} from "@mui/material";

const BailInsuranceRead =
  ({
     edit,
     guarantee,
     guaranteeData,
  }) => {
  return (
    <>
      {guarantee === 'bail_insurance' && !edit &&

        <Box
          sx={{
            // paddingX: '1rem'

          }}
        >
          {guaranteeData?.insurance_policy_number &&
            <SimpleTypography
              label={'Número de Apólice/Contrato'}
              directValue={guaranteeData?.insurance_policy_number}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.insurance_company &&
            <SimpleTypography
              label={'Companhia'}
              directValue={guaranteeData?.insurance_company}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.value &&
            <SimpleTypography
              label={'Valor do seguro'}
              directValue={guaranteeData?.value}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }

          {guaranteeData?.installments &&
            <SimpleTypography
              label={'Parcelas'}
              directValue={guaranteeData?.installments}
              labelStyles={{
                fontWeight: 'bold'
              }}
              boxStyles={{
                width: '100%',
                mb: '1rem'
              }}
            />
          }
        </Box>
      }
    </>
  )
}

export default BailInsuranceRead