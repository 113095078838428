import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#FDD1D0',
  light: '#F47287',
  main: '#DD1960',
  dark: '#9F0C5E',
  darker: '#6A0451',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#DCEEF5',
  light: '#88AEC5',
  main: '#1F3040',
  dark: '#0F1C2E',
  darker: '#050D1E',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#CCFFFD',
  light: '#66EEFF',
  main: '#00BFFF',
  dark: '#006FB7',
  darker: '#00397A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FDD4',
  light: '#A9F37D',
  main: '#52D829',
  dark: '#209B14',
  darker: '#07670C',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FDFBCB',
  light: '#F8EF63',
  main: '#EAD700',
  dark: '#A89700',
  darker: '#706300',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE5D6',
  light: '#FF9D83',
  main: '#FF3932',
  dark: '#B7192E',
  darker: '#7A092B',
  contrastText: '#fff',
};

const NEUTRAL = {
  lighter: '#FEFEFE',
  light: '#FBFCFD',
  main: '#F7F8F9',
  dark: '#7C92B3',
  darker: '#2F4577',
  contrastText: '#304a5f',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  neutral: createGradient(NEUTRAL.light, NEUTRAL.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  neutral: { ...NEUTRAL },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
