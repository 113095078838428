import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import GlobalSwitch from "../../../../components/Shared/GlobalSwitch";
import SimpleText from "../../../../components/Shared/SimpleText";
import React from "react";

const SlipOptionsInputs =
  (
    {
      reqData,
      setReqData,
      errorHandling,
      handleBankFineChange,
      bankFine,
      bankInterest,
      handleBankInterestChange,
      error


    }
  ) => {

  return (
    <>
      <Box
        sx={{
          mt: '1rem',
          display: 'flex',
          flexDirection: 'column',
          // paddingX: '2rem'
        }}
      >

        <GlobalSwitch
          label={'Geração Automática'}
          state={reqData}
          setState={setReqData}
          keyName={'generate_bankslip'}
          styles={{mb: '0.3rem'}}
        />

        <GlobalSwitch
          label={'Enviar para o e-mail?'}
          state={reqData}
          setState={setReqData}
          keyName={'send_email'}
          styles={{mb: '0.3rem'}}
        />
        <Fade
          in={reqData?.generate_bankslip}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box
            sx={{
              // paddingX: '2rem',
              mb: '1rem'
            }}
          >
            <SimpleText
              required
              label={'Gerar dia'}
              state={reqData}
              setState={setReqData}
              keyName={'generate_bankslip_day'}
              onlyNumbers
              directError={errorHandling()}

            />
          </Box>
        </Fade>


        <FormControl>
          <FormLabel>Multa</FormLabel>

          <RadioGroup
            row
            onChange={handleBankFineChange}
            value={bankFine}
          >
            <FormControlLabel value="value" control={<Radio size={'small'} />} label="Fixo" />
            <FormControlLabel value="percent" control={<Radio size={'small'} />} label="Percentual" />
          </RadioGroup>
        </FormControl>

        <Box
          sx={{
            // paddingX: '2rem',
            mb: '1rem',
          }}
        >
          <SimpleText
            label={'Valor'}
            state={reqData}
            setState={setReqData}
            keyName={bankFine === 'value' ? 'bankslip_fine_value' : 'bankslip_fine_percent' }
            onlyNumbers={bankFine === 'percent' && true}
            money={bankFine === 'value' && true}
            InputProps={{
              ...(bankFine === 'percent' && {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }),
            }}
          />
        </Box>

        <FormControl>
          <FormLabel >Juros</FormLabel>
          <RadioGroup
            row
            value={bankInterest}
            onChange={handleBankInterestChange }
          >
            <FormControlLabel value="value" control={<Radio size={'small'}/>} label="Fixo" />
            <FormControlLabel value="percent" control={<Radio size={'small'}/>} label="Percentual" />
          </RadioGroup>
        </FormControl>
        <Box
          sx={{
            // paddingX: '2rem',
            mb: '1rem',
          }}
        >
          <SimpleText
            label={'Valor'}
            state={reqData}
            setState={setReqData}
            keyName={bankInterest === 'value' ? 'bankslip_interest_value' : 'bankslip_interest_percent' }
            onlyNumbers={bankInterest === 'percent' && true}
            money={bankInterest === 'value' && true}
            InputProps={{
              ...(bankInterest === 'percent' && {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }),
            }}
          />
        </Box>
        <Box
          sx={{mb: '0.6rem'}}
        >

          <FormLabel>
            Vencimento
          </FormLabel>

        </Box>

        <Box
          sx={{
            // paddingX: '2rem',
            mb: '1rem',
          }}
        >
          <SimpleText
            label={'Dia'}
            state={reqData}
            setState={setReqData}
            keyName={'bankslip_due_day'}
            error={error}
            onlyNumbers
          />
        </Box>
      </Box>


    </>
  )
}

export default SlipOptionsInputs
