import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Add, ReceiptLong } from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { api } from '../../../services/Main/Api'
import successAnimation from '../../../assets/animations/success.json'
import AnimationGlobal from '../../../components/Shared/animations/AnimationGlobal'
import CircularProgress from '@mui/material/CircularProgress'
import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import RentContractFeeDetail from './RentContractFeeDetail'
import Context from '../../../contexts/Context'

function RentContractFee(props) {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////
  const { id } = useParams();
  const {dataProvider} = useContext(Context)
  //const { data, onChange, setTaxDelete, isLoading, taxUpdate, taxCreate, taxDeleteById, getTax } = props;
  const [filters, setFilters] = useState({ fully_paid: false })

  const [ data, setData] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [handleItem, setHandleItem] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [idDetailDialog, setIdDetailDialog] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [activeBackdrop, setActiveBackdrop] = useState(false);
  const [year, setYear] = useState(moment().startOf('date').format('YYYY'));
  const [month, setMonth] = useState(moment().startOf('date').format('MM'));
  const [recurringEndYear, setRecurringEndYear] = useState(null);
  const [recurringEndMonth, setRecurringEndMonth] = useState(null);

  const [value, setValue] = useState(0);
  const [valueFormated, setValueFormated] = useState("R$ 0,00");
  const [category, setCategory] = useState("");
  const [recurringSelected, setRecurringSelected] = useState("ALWAYS");
  const [recurringText, setRecurringText] = useState("Sem data de termino");
  const [invoiceRule, setInvoiceRule] = useState("INVOICE_SUM_VALUE");
  const [transferRule, setTransferRule] = useState("NO_ACTION");
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [recurringTypeChoices, setRecurringTypeChoices] = useState([]);
  const [recurringType, setRecurringType] = useState("EVERYDAY");
  const [recurringCustom, setRecurringCustom] = useState(48);
  const [invoiceRuleChoices, setInvoiceRuleChoices] = useState([]);
  const [transferRuleChoices, setTransferRuleChoices] = useState([]);
  const [description, setDescription] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [installment, setInstallment] = useState(false);
  const [installmentStart, setInstallmentStart] = useState(0);
  const [installmentNumber, setInstallmentNumber] = useState(2);
  const [toogleTypeBilling, setToogleTypeBilling] = useState("month");
  const [includeIncomeTax, setIncludeIncomeTax] = useState(false);
  const [proportionalCalculation, setProportionalCalculation] = useState(false);
  const [idRent, setIdRent] = useState(id || dataProvider?.rentId);


  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  function handleAddData () {
    setOpenDialog(true)
  }

  useEffect(()=>{
    setValue(parseInt(valueFormated.replace(/[^0-9]/g, '')))
  }, [valueFormated])

  function resetFields() {
    setValue(0)
    setValueFormated("0,00")
    setRecurring(false)
    setInstallment(false)
    setInvoiceRule("INVOICE_SUM_VALUE")
    setTransferRule("NO_ACTION")
    setInstallmentNumber(2)
    setInstallmentStart(0)
    setCategory("")
    setRecurringCustom(48)
    setRecurringType("EVERYDAY")
    setDescription(null)
  }

  useEffect(()=> {
    setIdRent(id || dataProvider?.rentId)
  }, [dataProvider, id])

  function handleSaveFee () {
    setIsSaving(true)
    setOpenDialog(false)
    setActiveBackdrop(true)

    let reference_date  = moment(`${year}-${month}`, 'YYYY-MM').format("YYYY-MM-DD");

    const data = {
      value,
      year,
      month,
      category,
      recurring,
      description,
      installment_start: null,
      installments: false,
      invoice_rule:invoiceRule,
      transfer_rule: transferRule,
      rent_contract: idRent,
      recurring_type: null,
      recurring_end_month: null,
      recurring_end_year: null,
      reference_date: reference_date,
      include_income_tax: includeIncomeTax,
      apply_proportional_calculation: proportionalCalculation,
    }


    if (installment === true){
        data.installment = installment
        data.installment_start = installmentStart
        data.installment_total_number = installmentNumber
    }

    if (recurring === true) {
      data.recurring_type = recurringType
      if (recurringType !== 'EVERYDAY')
        data.recurring_end_month = recurringEndMonth
        data.recurring_end_year = recurringEndYear
      }
    console.log(data)
    api.post("/rent-contract/fee/", data).then(response => {

      setTimeout(() => {
        setIsSuccess(true)
        getRentContractFee()
        resetFields()

      }, 2000);

    }).catch(error => {
      console.log(error)
      setOpenDialog(true)
    }).finally(data => {
      setIsSaving(false)
      setTimeout(() => {
        setActiveBackdrop(false)
        setIsSuccess(false)
      }, 4000);

    })
  }


  function getRentContractFee(){
    api.get(`/rent-contract/fee/?rent_contract=${idRent}&type_billing=${toogleTypeBilling}`).then(response => {
      const {data} = response

      setData(data)
    }).catch(error => {
      console.log(error)
    })
  }

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }

  const [installmentRange, setinstallmentRange] = useState(range(1, 48));

  useEffect(() => {
    api.get("/rent-contract/choices/").then(response => {
      setCategoryChoices(response.data.category_choices)
      setInvoiceRuleChoices(response.data.invoice_choices)
      setTransferRuleChoices(response.data.transfer_choices)
      setRecurringTypeChoices(response.data.recurring_type_choices)
    }).catch(error => {
      console.log(response)
    })

    getRentContractFee()
  }, [])

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleMenuClose = (item) => {
    setAnchorEl(null);
    console.log(item);
    setHandleItem(item.id)


  };

  useEffect(() => {
    setOpenDelete(true)
  }, [handleItem])
  function handleTypeBilling(e, newOption){
      setToogleTypeBilling(newOption)
  }

  useEffect(()=>{
    getRentContractFee()
  },[toogleTypeBilling])

  function handleDelete(itemId){

    api.delete(`rent_contract/fee/${itemId.id}/`).then(response => {
      getRentContractFee()
      setOpenDelete(false)
    }).catch(error => {
        console.log(error)
    })
  }

  useEffect(()=>{
    const choices = {
      THREE_MONTHS: 3,
      SIX_MONTHS: 6,
      TWELVE_MONTHS: 12,
      TWENTY_FOUR_MONTHS: 24,
      THIRTY_MONTHS: 30,
      CUSTOM: recurringCustom,
    }
    if (recurringType === "EVERYDAY"){
      setRecurringText(`Sem data de término `)
    }else{
      let months = choices[recurringType]
      if (months !== undefined){
        let today  = moment(`${year}-${month}`, 'YYYY-MM').add(months, 'months');
        setRecurringText(`Recorrente até ${today.format("MMMM [de] YYYY")}`)
        setRecurringEndYear(today.format("YYYY"))
        setRecurringEndMonth(today.format("MM"))
      }
    }


  }, [recurringType, year, month, recurringCustom])

  return (
    <Grid item xs={12}>
      {idDetailDialog &&  <RentContractFeeDetail
         openDialog={openDetailDialog}
         handleDialogClose={()=>{setOpenDetailDialog(false)}}
         idFee={idDetailDialog}
         loadRentContractFee={getRentContractFee}
       /> }
      {!isLoading ?
        <Card>
          <CardContent>
          <Grid container spacing={2} >
            <Grid item md={6} sm={12}>
              <Box sx={{display:"flex", alignItems: "center"}}>
              <Avatar sx={{bgcolor: 'primary.main'}}><ReceiptLong/></Avatar>
                <Typography variant={"h5"} sx={{ml:1}}>Cobranças</Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={12} sx={{textAlign:"right"}}>
              <ToggleButtonGroup
                color="primary"
                size={"small"}
                value={toogleTypeBilling}
                exclusive
                onChange={handleTypeBilling}
                aria-label="Platform"
              >
                <ToggleButton value="month">
                  <Tooltip title="Cobrança Mensal">
                  <CalendarMonthOutlinedIcon />
                  </Tooltip>
                  </ToggleButton>
                <ToggleButton value="recurring">
                  <Tooltip title="Cobranças Recorrente">
                    <AllInclusiveOutlinedIcon />
                  </Tooltip>
                  </ToggleButton>
                <ToggleButton value="installment">
                  <Tooltip title="Cobranças Parcelada">
                    <CreditScoreOutlinedIcon />
                  </Tooltip>
                  </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item md={2} sm={12} sx={{alignItems: "center", display: "flex"}}>
              <Button
                variant="contained"
                startIcon={<Add/>}
                size={"small"}
                onClick={handleAddData}

              > Nova Cobrança
              </Button>
            </Grid>
          </Grid>
          </CardContent>

          <Divider />

          <TableContainer>
            {data?.results?.length > 0  ?
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Mês/Ano</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>Fatura</TableCell>
                    <TableCell>Repasse</TableCell>

                    <TableCell sx={{textAlign:"right"}}>Valor</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>


                  { data.results.map(item =>
                    <TableRow
                      key={item.id}
                      hover
                      sx={item.total < 0 ? { backgroundColor: "warning.lighter", 'cursor': 'pointer' } : {'cursor': 'pointer'}}

                      onClick={(e) => {
                        e.stopPropagation()

                        setIdDetailDialog(item.id)
                        setOpenDetailDialog(true)
                      }}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          COB-{item.id}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {moment(`${item?.month}-${item?.year}`, 'MM-YYYY').format('MMM/YYYY')}

                        </Box>
                      </TableCell>
                      <TableCell>{item.category}</TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {item.invoice_rule}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {item.transfer_rule}
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: "bold",
                            justifyContent: "end"

                          }}

                        >
                          {Masks.money(item?.value.toString())}

                        </Box>
                      </TableCell>


                    </TableRow>
                  )}

                </TableBody>
              </Table>
              :
              <NoResultsAnimation />
            }
          </TableContainer>


          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >


              <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openDialog}
                onClose={handleDialogClose}
              >
                <DialogTitle>Criar Cobrança</DialogTitle>
                <Divider/>

                <DialogContent>

                  <Box
                    noValidate
                    component="form"

                  >
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth required={true}>
                          <InputLabel id="category-select-label">Categoria</InputLabel>
                          <Select
                            labelId="category-select-label"
                            id="category-select"
                            defaultValue={category}
                            onChange={(event)=> {
                              let rules = event.target.value.rules

                              setCategory( event.target.value.id)
                              setInvoiceRule(rules.invoice_rule)
                              setTransferRule(rules.transfer_rule)
                              setRecurring(rules.recurring)
                              setRecurringType(rules.recurring_type)
                            }}
                            input={<OutlinedInput label="Categoria" />}
                          >
                            {categoryChoices.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item}
                                //style={getStyles(name, personName, theme)}
                              >
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth >
                          <MobileDatePicker
                            openTo={'month'}
                            views={['year', "month"]}

                            label="Mês de Cobrança"
                            value={moment(`${year}-${month}`, 'YYYY-MM').format()}
                            onChange={(e)=> {
                              setYear(moment(e).format('YYYY'))
                              setMonth(moment(e).format('MM'))
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "MMMM AAAA",
                                  readOnly: true,

                                }}


                                required
                                sx={{
                                  maxWidth: '200px',
                                  mr: '0.5rem',
                                }}
                              />
                            )}
                          />

                        </FormControl>
                      </Grid>



                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth required={true}>
                          <InputLabel >Valor</InputLabel>
                          <OutlinedInput
                            onChange={(event) => {
                              setValueFormated(Masks?.money(event.target.value))
                            }}
                            value={valueFormated}
                            label="Valor"
                          />
                        </FormControl>
                      </Grid>

                       <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-textarea"
                            label="Descrição"
                            placeholder="Descrição"
                            multiline
                            rows={1}
                            defaultValue={description}
                            onChange={(event)=> {setDescription(event.target.value)}}
                          />
                        </FormControl>
                      </Grid>

                    </Grid>



                  </Box>
                </DialogContent>
                <Divider  />
                <DialogTitle >Regras</DialogTitle>

                <DialogContent >
                  <Grid container spacing={2} mt={.5}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth >
                        <InputLabel id="invoice-select-label">Fatura</InputLabel>
                        <Select
                          labelId="invoice-select"
                          id="invoice-select"
                          value={invoiceRule}
                          label="fatura"

                          onChange={(pointer)=> {
                            setInvoiceRule(pointer.target.value)
                          }}
                        >
                          {invoiceRuleChoices.map((item, index) =>
                            <MenuItem
                              key={index}
                              value={item.id}
                            >
                              {item.value}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth >
                        <InputLabel id="transfer-select-label">Repasse</InputLabel>
                        <Select
                          labelId="transfer-select"
                          id="transfer-select"
                          value={transferRule}
                          label="Repasses"

                          onChange={(pointer)=> {
                            setTransferRule(pointer.target.value)
                          }}
                        >
                          {transferRuleChoices.map((item, index) =>
                            <MenuItem
                              key={index}
                              value={item.id}
                            >
                              {item.value}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth >
                        <FormLabel sx={{color:"#333"}} id="irrf-radio-buttons-group-label">
                          Incluir no IRRF <Tooltip title="Esse campo inclui ou não o valor na dedução do Imposto de Renda Retido na Fonte. "><InfoOutlinedIcon fontSize={"12"} /></Tooltip>
                        </FormLabel>

                        <RadioGroup
                          row
                          aria-labelledby="irrf-radio-buttons-group-label"
                          name="irrf-radio-buttons-group"
                          defaultValue={includeIncomeTax}
                          onChange={(event) => {
                            setIncludeIncomeTax(event.target.value)
                          }}
                        >
                          <FormControlLabel value={true} control={<Radio />} label="Sim" />
                          <FormControlLabel value={false} control={<Radio />} label="Não" />

                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth >

                        <FormLabel sx={{color:"#333"}} id="demo-row-radio-buttons-group-label">
                          Cobrança Proporcional <Tooltip title="Este campo somente é valido para calcular a proporcionalidade no primeiro mês de contrato."><InfoOutlinedIcon fontSize={"12"} /></Tooltip>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue={proportionalCalculation}
                          onChange={(event) => {
                            setProportionalCalculation(event.target.value)
                          }}
                        >
                          <FormControlLabel value={true} control={<Radio />} label="Sim" />
                          <FormControlLabel value={false} control={<Radio />} label="Não" />

                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <Divider  />
                <DialogTitle sx={{display:"flex", justifyContent:"space-between"}}>
                  Cobrança Recorrente
                  <Switch
                  checked={recurring}
                  onChange={() => setRecurring(!recurring)}
                  name="recurringBilling"
                  color="primary"
                  disabled={installment}
                />
                </DialogTitle>
                { recurring && <DialogContent >
                  <Grid container spacing={2} mt={.5}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth >

                        { recurringType === "CUSTOM" ?
                          <>
                            <InputLabel >Número de meses</InputLabel>
                            <OutlinedInput placeholder="Número de meses"
                                           label={"Número de meses"}
                                           defaultValue={recurringCustom}
                                           onChange={(event)=>{
                                             setRecurringCustom(event.target.value)
                                           }}
                            />
                          </>
                          :
                          <>
                          <InputLabel id="transfer-select-label">Recorrente</InputLabel>
                          <Select
                          labelId="transfer-select"
                          id="transfer-select"
                          defaultValue={recurringType}
                          label="Recorrente"

                          onChange={(event)=> {
                            setRecurringType(event.target.value)
                          }}
                        >
                          {recurringTypeChoices.map((item, index) =>
                            <MenuItem key={index} value={item.id}>{item.value}</MenuItem>
                          )}

                        </Select> </>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Alert severity="info">{recurringText}</Alert>

                    </Grid>
                  </Grid>
                </DialogContent> }
                <Divider  />

                <DialogTitle sx={{display:"flex", justifyContent:"space-between"}}>
                  Parcelamento
                  <Switch
                    checked={installment}
                    onChange={() => setInstallment(!installment)}
                    name="installment"
                    color="primary"
                    disabled={recurring}
                  />
                </DialogTitle>

                { installment && <DialogContent>
                  <Grid container spacing={2} mt={.5}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth >
                        <InputLabel id="installment-select-label">Parcelar em</InputLabel>
                        <Select
                          labelId="installment-select"
                          id="installment-select"
                          defaultValue={installmentNumber}
                          label="Parcelar em"

                          onChange={(pointer)=> {

                            setInstallmentNumber(pointer.target.value)
                          }}
                        >
                          {installmentRange.map((item, index) =>
                            <MenuItem key={index} value={item}>{item}x</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth >
                        <InputLabel id="installment-select-label">Iniciar na Parcela</InputLabel>
                        <Select
                          labelId="installment-select"
                          id="installment-select"
                          defaultValue={installmentStart}
                          label="Iniciar Parcela em"

                          onChange={(pointer)=> {

                            setInstallmentStart(pointer.target.value)
                          }}
                        >
                          <MenuItem value={0}>Selecione</MenuItem>
                          {installmentRange.map((item, index) =>
                            <MenuItem key={index} value={item}>{item}ª Parcela</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
              </DialogContent> }
                <Divider />
                <DialogActions sx={{display:"flex", justifyContent:"space-between", padding: "2rem 1rem"}}>
                  <Button variant="text" color={"secondary"} onClick={()=> handleDialogClose() }>Cancelar</Button>
                  <Button variant="contained" disabled={isSaving} color={"primary"} onClick={()=> handleSaveFee() }>{isSaving ? "Salvando" : "Salvar" }</Button>
                </DialogActions>
              </Dialog>

            </Box>
          </CardContent>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={activeBackdrop}
            // onClick={() => {
            //   if (isSaving === false){
            //     setActiveBackdrop(false)
            //   }
            //
            // }}
          >
            { !isSuccess ? <CircularProgress color="inherit" />
            : <AnimationGlobal
              text={'Sua cobrança foi criada com sucesso!'}
              size={'200px'}
              animation={successAnimation}
              fontStyles={{mb: '1rem'}}
              fontSize={'20px'}
              color="#FFFFFF"
            /> }
          </Backdrop>
        </Card>

        :
        <Skeleton
          height={300}
          width={'100%'}
          variant='rounded'
        />
      }



    </Grid>
  );
}

export default RentContractFee