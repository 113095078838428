import {Box, Fade, TextField} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import React from "react";

const CredPagoInputs =
  ({
    edit,
    guarantee,
    guaranteeData,
    setGuaranteeData,

   }) => {

  return (
    <Box
      sx={{
        // paddingX: '1rem'
      }}
    >
      {guarantee === 'credpago' && edit &&
        <Fade
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <SimpleText
              label={'Número de Apólice/Contrato'}
              // onlyNumbers
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'insurance_policy_number'}
              styles={{ mb: '1rem' }}
            />

            <DatePicker
              sx={{ textAlign: 'center' }}
              views={['year', 'month', 'day']}
              label="Data de Vencimento"
              // minDate={moment()}
              value={moment(guaranteeData?.due_date)}
              onChange={(newValue) => setGuaranteeData((prevState) => ({
                ...prevState,
                due_date: moment(newValue).format('YYYY-MM-DD')
              }))}
              renderInput={
                (params) => (
                  <TextField
                    {...params}
                    size={'small'}
                    sx={{ mb: 2 }}
                    helperText={null}
                    fullWidth
                  />
                )
              }
            />

            <SimpleText
              label={'Valor'}
              money
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'value'}
              styles={{ mb: '1rem' }}
            />
          </Box>
        </Fade>
      }
    </Box>
  )
}

export default CredPagoInputs