import React from "react";
import {
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";

const TableCondoHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: 'primary.main' }}>
          Nome
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          Estado
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          Cidade
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          Telefone
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
          E-MAIL
        </TableCell>
        <TableCell sx={{ color: 'primary.main' }}>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableCondoHead
