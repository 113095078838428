import {Alert, Box, Button, Fade, FormHelperText, Typography, useMediaQuery} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import {Forward} from "@mui/icons-material";
import React, {useContext, useEffect} from "react";
import Context from "../../../../contexts/Context";
import AuthValidation from "../../../../components/Shared/AuthValidation";

const PaymentChecker = (props) => {
  const { filters, sessionCheck, dataProvider } = useContext(Context)

  const { data, setData, handleSubmitPreview, handleSubmitPayment, error, paymentReq } = props

  const sizeMatch = useMediaQuery('@media (min-width:900px)')


  useEffect(function checkSession(){
    if (dataProvider?.validation === true) {
      handleSubmitPreview()
    }
  }, [dataProvider?.validation])


  return (
    <>
      <Fade
        in={!dataProvider?.paymentStep}
        mountOnEnter
        unmountOnExit
        timeout={300}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingX: '1rem',

          }}
        >

          <SimpleText
            label={'Código de barras'}
            variant={'standard'}
            onlyNumbers
            state={data}
            directError={error}
            directHelper={error}
            setState={setData}
            keyName={'barcode'}
            styles={{
              // mr:'1rem',
              maxWidth: '600px',

            }}

          />
          <Button
            disabled={!!!data?.barcode}
            onClick={() => {
               !dataProvider?.validation && sessionCheck()
                dataProvider?.validation && handleSubmitPreview()
              }
            }
          >
            Consultar
          </Button>
          {filters?.payment &&
            <Alert
              variant={'outlined'}
              severity="info"
              sx={{
                border: 'none',
                marginY: '1rem',
                boxShadow: 'none'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
              }}
            >
              Etapa necessária para criar a despesa
            </Alert>
          }
        </Box>
      </Fade>

      <Fade
        in={!!paymentReq?.Barcode}
        mountOnEnter
        unmountOnExit
        timeout={300}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: '1rem'
          }}

        >


        </Box>
      </Fade>
      <AuthValidation />


    </>
  )
}

export default PaymentChecker