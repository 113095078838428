import {IconButton, Tooltip} from "@mui/material";
import {DescriptionOutlined, Download} from "@mui/icons-material";
import React from "react";

const InvoiceDetailDownload = ({ invoice }) => {
  return (
    <Tooltip
      title={invoice?.bank_slip_status.key !== 5 ? 'Baixar Boleto' : 'Boleto Pago'}
      arrow
      placement={'top'}
    >
      <span>
        <IconButton
          disabled={!invoice?.bank_slip?.length || invoice?.bank_slip_status?.key === 5}
          target={'_blank'}
          href={invoice?.bank_slip?.length ? invoice?.bank_slip[0]?.file : void (0)}
        >
          <Download/>
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default InvoiceDetailDownload