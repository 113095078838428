import React, {useContext} from "react";
import moment from "moment";
import {
  Box,
  Divider, Skeleton,
  Typography,
} from "@mui/material";
import CardStatement from "./CardStatement";
import NoResultsAnimation from "../../../../../components/Shared/animations/NoResultsAnimation";
import Context from "../../../../../contexts/Context";

const Statements = ({
    data,
    hasData
  }) => {

  const { dataProvider } = useContext(Context)

  const dataGroups = [...new Set(data?.map(entry => entry.EntryDate.split('T')[0]))].map(date => {
    return {
      date,
      entries: data.filter(entry => entry.EntryDate.split('T')[0] === date).map(entry => {
        const time = entry.EntryDate.split('T')[1].substring(0, 5);
        return { ...entry, time };
      })
    }
  });

  const finalFormat = dataGroups?.slice(1,-1);

  return (
    <>
        {dataProvider?.loadingStatement === false ?
        <>
          {finalFormat?.length ?
          <>
          {finalFormat?.map((e, index) => (
            <Box
              key={e.date}
            >
              <Box
                sx={{
                  mt: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography
                  align={'center'}
                  variant="overline"
                  sx={{
                    textTransform: 'none',
                    mb: '1rem',
                    fontSize: '1rem'

                  }}
                >
                  {moment(e.date).locale('pt-br').format('dddd - D [de] MMMM').replace(/^\w/, (char) => char.toUpperCase())}
                </Typography>

                <CardStatement
                  e={e}
                  hasData={hasData}
                />
              </Box>
              <Divider/>
            </Box>

          ))}
          </>
          :
        <NoResultsAnimation/>
          }
        </>
        :
          <Skeleton
            variant="rounded"
            height={700}
            animation="pulse"
          />
      }

    </>
  )
}

export default Statements