import React, { useContext, useEffect, useState } from 'react'
import {
  Backdrop,
  Box,
  Card,
  Chip,
  Container,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import { Alarm } from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const SignedRecipientTransferList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const { loadingModal, filters, setFilters } = useContext(Context)

  const monthFormat = moment().format("MM")
  const yearFormat = moment().format("YYYY")
  const location = useLocation();

  useEffect(()=>{
    setSearchParams({ ...searchParams, "month": monthInput, "year": yearInput})
  }, [monthInput, yearInput])

  function getData(){
    setLoading(true)
    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }

    let filter = {...filters, month:month, year:year}
    if (searchParams.get("rent_contract_id")){
      filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
    }

    if (searchParams.get("invoice_id")){
      filter = {...filter, invoice_id: searchParams.get("invoice_id")}
    }

    api.get(`signed-recipient-transfer/`, {params: filter})
      .then(response => {
        const data = response.data
        setResponse(data)
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
    })
  }


  useEffect(() => {
    if (searchParams.get("month") && searchParams.get("year") ){
      setMonthInput(searchParams.get("month"))
      setYearInput(searchParams.get("year"))
    }else {
      getData()
    }
  }, [])

  useEffect(() => {
    getData()
  }, [searchParams])


  return (
    <>
      <Helmet>
        <title>Repasses - Bloco B3</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Repasses ({response?.count || 0})
          </Typography>
          <Box  sx={{textAlign: "right"}}>
            <DatePicker
              openTo="month"
              views={['year', 'month']}
              label="Mês de Pagamento"
              inputProps={{ readOnly: true }}
              disableMaskedInput
              value={moment(`${monthInput}-${yearInput}`, 'MM-YYYY').format()}
              onChange={(newValue) => {
                setMonthInput(moment(new Date(newValue)).format("MM"))
                setYearInput(moment(new Date(newValue)).format("YYYY"))
              }}
              renderInput={(params) =>
                <TextField

                  {...params}
                />}
            />
          </Box>
        </Box>


        <Card>
          {response?.results ?
            <>

              <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
                <Grid item xs={12} sm={4} lg={3}>
                  {/*<UnitFilter />*/}
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  {/*<ReferenceMonthFilter*/}
                  {/*  response={response}*/}
                  {/*/>*/}
                </Grid>
              </Grid>

              <TableContainer>
                {response?.results?.length > 0  ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Beneficiário</TableCell>
                        <TableCell>Referência</TableCell>

                        <TableCell>Contrato</TableCell>
                        <TableCell>Fatura</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell align={"center"}>Status</TableCell>
                        <TableCell></TableCell>


                    </TableRow>
                    </TableHead>
                    <TableBody>


                    { response.results.map(item =>
                      <TableRow
                        key={item.id}
                        hover
                        sx={{'cursor': 'pointer'}}

                        onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/repasses/${item.id}`)
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.number}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.name} -  {item.percentage}%
                          </Box>
                        </TableCell>
                        <TableCell>
                          {moment(item.reference_date, "YYYY-MM-DD").subtract(1, 'month').format("MMM/YYYY")}

                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.rent_contract.number}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {item.invoice.number}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            color={item?.total < 0 && "error.main" }
                          >
                            {Masks.money(item?.total.toString())}

                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Tooltip title={item?.status.id === 'PAID' ? `Pago em ${moment(item?.transfered_at).format('DD/MM/YYYY [às] H:mm')}` :
                              item?.status.id === 'ERROR' ? `${item?.status_message}` :
                              item?.status.id === 'DUE' ? `${item?.status_message}` :
                              item?.status.id === 'FINALIZED' ? `${item?.status_message}` :
                              item?.status.id === 'PROCESSING' ? `${item?.status_message}` :
                              item?.status.id === 'FAILED' ? `${item?.status_message}` :
                              `Criado em ${moment(item.created_at).format('DD/MM/YYYY [às] H:mm')}`} >
                              <Chip
                                color={item?.status.id === 'PAID' ? 'success' :
                                  item?.status.id === 'PENDING' ? 'warning' :
                                  item?.status.id === 'SCHEDULED' ? 'warning' :
                                    item?.status.id === 'FINALIZED' ? 'success' :
                                      item?.status.id === 'PROCESSING' ? 'primary' :
                                      item?.status.id === 'ERROR' ? 'error' :
                                      item?.status.id === 'DUE' ? 'error' :
                                      item?.status.id === 'FAILED' ? 'error' :
                                        item?.status.id === 'OPEN' && 'info'}
                                size="small"
                                variant="outlined"
                                label={item?.status.value}/>
                            </Tooltip>


                          </Box>
                        </TableCell>


                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >


                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    </TableBody>
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
        </Card>

      </Container>
      {/*{response?.count > 0 &&*/}
      {/*  <PaginationGlobal*/}
      {/*    state={response}*/}
      {/*    filters={filters}*/}
      {/*    setFilters={setFilters}*/}
      {/*  />*/}
      {/*}*/}
    </>
  )
}

export default SignedRecipientTransferList
