import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import {useLocation} from "react-router-dom";
import Context from "../../../../../contexts/Context";

const TableUnitHead = () => {
  const location = useLocation()

  const {filters, setFilters} = useContext(Context)

  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-name' || filters?.ordering === 'name'}
            direction={filters?.ordering === '-name' ? 'desc' : 'asc'}
            onClick={() => handleFilter('name', '-name') }
          >
            Nome
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-number' || filters?.ordering === 'number'}
            direction={filters?.ordering === '-number' ? 'desc' : 'asc'}
            onClick={() => handleFilter('number', '-number') }
          >
           Nº do imóvel
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-block.identifier' || filters?.ordering === 'block.identifier'}
            direction={filters?.ordering === '-block.identifier' ? 'desc' : 'asc'}
            onClick={() => handleFilter('block.identifier', '-block.identifier') }
          >
            Bloco
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-email' || filters?.ordering === 'email'}
            direction={filters?.ordering === '-email' ? 'desc' : 'asc'}
            onClick={() => handleFilter('email', '-email') }
          >
            E-mail
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-phone' || filters?.ordering === 'phone'}
            direction={filters?.ordering === '-phone' ? 'desc' : 'asc'}
            onClick={() => handleFilter('phone', '-phone') }
          >
           Telefone
          </TableSortLabel>
        </TableCell>

        <TableCell
          sx={{
            color: 'primary.main'
          }}
        >
          <TableSortLabel
            style={{ color: '#DD1960' }}
            active={filters?.ordering === '-status' || filters?.ordering === 'status'}
            direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
            onClick={() => handleFilter('status', '-status') }
          >
            Situação
          </TableSortLabel>
        </TableCell>
        {!location.pathname.includes('alugueis') &&
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-percent_meter' || filters?.ordering === 'percent_meter'}
              direction={filters?.ordering === '-percent_meter' ? 'desc' : 'asc'}
              onClick={() => handleFilter('percent_meter', '-percent_meter') }
            >
              Fração %
            </TableSortLabel>
          </TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default TableUnitHead