import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import {
  Circle,
  CurrencyExchange,
  MoneyOutlined,
  PaidOutlined,
  Receipt,
  Visibility,
  VisibilityOff
} from '@mui/icons-material'
import { useEffect, useRef, useState, useContext} from 'react'
import { getCondoId } from '../../services/Main/AuthStorage'
import { api } from '../../services/Main/Api'
import Menu from '@mui/material/Menu'
import Context from '../../contexts/Context'
import SimpleTypography from "../Shared/SimpleTypography";

const BalanceBar = ({ req }) => {
  const ref = useRef(null)
  const {condoIdLogin} = useContext(Context)
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [accData, setAccData] = useState(null)

  useEffect(() => {
    const condo = getCondoId()
    if (req && condo) {
    api.get(`fitbank/balance/${condoIdLogin ?? condo}/`)
      .then((response) => {
        setData(response.data)
      }).catch((error) => {
      console.log(error.response.data)
      setError(error.response.data)
      })
    }
  }, [req])

  useEffect(function accountData(){
    if(req && getCondoId()){
      api.get(`fitbank/${getCondoId()}/`)
        .then(response => {
          setAccData({
            bank: `Fitbank - ${response.data.spb_bank }`,
            bank_branch: response.data.spb_bank_branch,
            bank_account: `${response.data.spb_bank_account} - ${response.data.spb_bank_account_digit}`
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [req])

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency', currency: 'BRL',
  })


  return (
    <>
      <IconButton
        ref={ref}
        onClick={() => setOpen(true)}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <PaidOutlined color={'primary'}/>
      </IconButton>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 2, maxWidth: 250, minWidth: 250, width: '100%' }}>
          <SimpleTypography
            label={'Banco'}
            labelStyles={{ fontWeight: 'bold'}}
            isLoading={!accData}
            state={accData}
            keyName={'bank'}
            block={false}
            boxStyles={{ mb: '1rem'}}
          />

          <SimpleTypography
            label={'Agência'}
            labelStyles={{ fontWeight: 'bold'}}
            isLoading={!accData}
            state={accData}
            keyName={'bank_branch'}
            block={false}
            boxStyles={{ mb: '1rem'}}
          />
          <SimpleTypography
            label={'Conta'}
            labelStyles={{ fontWeight: 'bold'}}
            isLoading={!accData}
            state={accData}
            keyName={'bank_account'}
            block={false}
            boxStyles={{ mb: '1rem'}}

          />
          <Box
            display={'flex'}
            alignItems={'center'}
            height={16}

          >

            <Typography
              display={'flex'}
              alignItems={'center'}
              fontWeight={'bold'}

            >
              {'Saldo'}
              <IconButton
                disabled={!!error}
                onClick={() => setShow(prev => !prev)}
              >
                {show
                  ? <VisibilityOff sx={{ fontSize: 20, color: 'primary.main' }}/>
                  : <Visibility sx={{ fontSize: 20 }}/>
                }
              </IconButton>
            </Typography>
            <Box
              component={'div'}
              align="center"
              sx={{
                  // p: 1,
                  ml: 1,
                  fontWeight: 'bold',
                  // bgcolor: 'neutral.main',
                  // borderRadius: '.5rem'
              }}
              color={'secondary'}
              flexGrow={1}
            >
              {!show ?
                <Box textAlign={'right'}>
                  <Circle sx={{ fontSize: 8, color: 'secondary.main', mr: 0.5 }}/>
                  <Circle sx={{ fontSize: 8, color: 'secondary.main', mr: 0.5 }}/>
                  <Circle sx={{ fontSize: 8, color: 'secondary.main', mr: 0.5 }}/>
                  <Circle sx={{ fontSize: 8, color: 'secondary.main', mr: 0.5 }}/>
                  <Circle sx={{ fontSize: 8, color: 'secondary.main', mr: 0.5 }}/>
                  <Circle sx={{ fontSize: 8, color: 'secondary.main' }}/>
                </Box>
                :
                <Typography
                  textAlign={'right'}
                  color={'secondary'}
                  // fontWeight={'bold'}
                >
                  {formatter.format(data?.balance)}
                </Typography>
              }
            </Box>
          </Box>
          <Stack mt={3}>
            <Button
              variant={'contained'}
              disabled={!!error}
              href="/transferencia"
              // startIcon={<CurrencyExchange/>}
              sx={{mb: 2}}
            >
              {'Transferir'}
            </Button>
            <Button
              variant={'contained'}
              disabled={!!error}
              href="/pagamento"
              // startIcon={<CurrencyExchange/>}
              sx={{mb: 2}}
            >
              {'Pagar'}
            </Button>
            <Button
              variant={'outlined'}

              // startIcon={<Receipt/>}
              href="/extrato">
              {'Ver Extrato'}
            </Button>
          </Stack>
        </Box>
      </Menu>
    </>
  )
}

export default BalanceBar
