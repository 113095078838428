import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import {
  Delete,
  Download,
  MoreVert,
  Share
} from "@mui/icons-material";
import moment from "moment";
import Masks from "../../../../../components/Shared/Masks";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {api} from "../../../../../services/Main/Api";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import Menu from "@mui/material/Menu";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import Context from "../../../../../contexts/Context";

const TableChargeBody = ({ data, filters, setFilters }) => {
  const {enqueueSnackbar} = useSnackbar()

  const [units, setUnits] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)

  const navigate = useNavigate()
  const unitValues = units?.map((option) => ({ name: `${option.number} | ${option.name}`, id: option.id }))

  async function fetchData() {
    try {
      const response = await api.get(`unit/?condo_id=${getCondoId()}`);
      setUnits(response?.data.results);
    } catch (error) {
      console.error(error?.data);
    }
  }

  const deleteReq = () => {
    api.delete(`/charge/${selectedObject?.id}/`)
      .then(response =>{
        setFilters(prevState => ({...prevState, deleteDone: !filters?.deleteDone}))
        enqueueSnackbar('Cobrança apagada', { variant: 'success' })
      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir cobrança', { variant: 'error' })
        error.response.data && enqueueSnackbar(error.response.data, { variant: 'error' })
        console.log(error)
      })
      .finally(() => {
      })
  }

  useEffect(function getUnits() {
    fetchData()
  }, [])


  return (
    <>
    <TableBody>
      {data?.results?.map(object =>
        <TableRow
          key={object.number}
          hover
          onClick={() => { navigate(`/faturas/cobranca/${object.id}`)}}
          sx={{ 'cursor': 'pointer', }}
        >
          <TableCell>
            {object.number}
          </TableCell>
          <TableCell>
            {unitValues?.find(element => element.id === object?.unit)?.name}
          </TableCell>
          <TableCell>
            {object.due_date
              ? moment(object.due_date).format('DD/MM/YYYY')
              : '-'}
          </TableCell>
          <TableCell>
            {Masks.money(String(object.total))}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={"center"}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedObject(object)
                setAnchor(e.currentTarget)
                setOpen(object.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === object.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  window.open(selectedObject?.bank_slip_charge[0]?.file, '_blank')
                  e.stopPropagation()
                }}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Download/>
                </ListItemIcon>
                <ListItemText
                  primary="Baixar boleto"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>

              <MenuItem
                onClick={ (e) => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(selectedObject?.bank_slip_charge[0]?.file)
                    .then(() => {
                      enqueueSnackbar('Link copiado', { variant: 'success' })
                    })
                }
                }
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Share/>
                </ListItemIcon>
                <ListItemText
                  primary="Compartilhar boleto"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpenDelete(true)
                  setOpen(false)
                  e.stopPropagation()
                }}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText
                  primary="Excluir Cobrança"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      )
      }
    </TableBody>
      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Apagar Cobrança'}
        question={'Gostaria realmente de apagar esta cobrança?'}
        functionProp={deleteReq}
      />
    </>
  )
}

export default TableChargeBody