import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../services/Main/Api'
import {
  Alert, Avatar,
  Backdrop,
  Box, Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid, IconButton, Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import Context from '../../../contexts/Context'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment/moment'
import {
  AccountBalance,
  Alarm,
  Article,
  Close,
  ContactMailOutlined,
  PictureAsPdf,
  PixOutlined,
  Receipt
} from '@mui/icons-material'
import TableInvoiceHead from '../Invoice/components/InvoiceList/TableInvoiceHead'
import TableInvoiceBody from '../Invoice/components/InvoiceList/TableInvoiceBody'
import InvoiceIcon from '../../../components/Invoice/InvoiceIcon'
import StatusInvoice from '../../../components/Invoice/StatusInvoice'
import Masks from '../../../components/Shared/Masks'
import MakeItPaidMenu from '../Invoice/MakeItPaidMenu'

const SignedRecipientTransferDetail = () => {
  const { filters, setFilters, loadingModal, setLoadingModal } = useContext(Context)

  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()


  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {

    getSignedRecipientTransferId()
    // getCondo()
    window.scrollTo(0, 0)


  }, [])

  const getSignedRecipientTransferId = async () => {
    setIsLoading(true)
    const url = `signed-recipient-transfer/${params.id}/`
    let result = await api.get(url).then(response => {
      console.log(response.data)
      setData(response.data)
      setIsLoading(false)
    })


  }


  return (
    <>

      <Helmet>
        <title>Repasse - Bloco B3</title>
      </Helmet>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1500, }}
          open={loadingModal}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>

        {/*{!isLoading &&*/}
      { data &&
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        sx={{ flexGrow: 1, padding: "1rem"  }}
      >
        <Grid md={8}>
          <Card
            sx={{padding:"1rem", margin: "0 1rem " }}>
            { !isLoading &&
              <>
                <CardContent >
                  <Grid
                    container
                    spacing={2}
                    justifyContent={'space-between'}
                    sx={{ flexGrow: 1}}
                  >
                    <Grid xs={6}>
                      <Box>
                        <Typography variant="h6"  sx={{
                          color: 'primary.main'
                        }}>{data?.number}</Typography>

                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box
                        border
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          alignItems: 'center'

                        }}
                      >

                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell >Código</TableCell>
                      <TableCell>Descrição</TableCell>

                      <TableCell width={150}>Valor</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { data?.fees.map(fee =>
                    <TableRow>

                      <TableCell>
                        COB-{fee.id}
                      </TableCell>
                      <TableCell>
                        {fee.description}
                      </TableCell>
                      <TableCell sx={fee.total < 0 ? {color:"error.main"} : {}}>
                        {Masks.money(fee.total.toString())}
                      </TableCell>
                    </TableRow> )}

                    {data?.percentage < 100 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align={"right"}><Typography level={"title-lg"}>Subtotal:</Typography></TableCell>
                      <TableCell><Typography level={"title-lg"}>{Masks.money(data?.subtotal.toString())} </Typography></TableCell>
                    </TableRow> }
                    {data?.income_tax ?
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align={"right"}><Typography level={"title-lg"}>Imposto de renda retido na fonte:</Typography></TableCell>
                        <TableCell><Typography level={"title-lg"}>{Masks.money(data?.income_tax.toString())} </Typography></TableCell>
                      </TableRow> : null }
                    {data?.percentage < 100 &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell colSpan={2} align={"right"}>
                        <Chip label={`Beneficiário possui somente ${data?.percentage}% do repasse`}/>
                      </TableCell>
                    </TableRow> }
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align={"right"}><Typography sx={{fontWeight:"bold",}} level={"h4"} color={data?.total < 0 && "error.main" }> Total do Repasse:</Typography></TableCell>
                      <TableCell><Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}} level={"h4"} color={data?.total < 0 && "error.main" }>{Masks.money(data?.total.toString())} </Typography> </TableCell>
                    </TableRow>
                  </TableBody>

                </Table>

              </>
            }

          </Card>
          <Card sx={{padding:"1rem", margin: "1rem " }}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", justifyContent:"space-between", padding: ".5rem 0 "}}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Article  sx={{
                    color: 'primary.main'
                  }}/>
                  <Typography sx={{marginLeft: ".5rem"}} color={"primary.main"} variant="h6">
                    Contrato</Typography>
                </Box>

              </Box>
              <Divider/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell

                    >
                      Código
                    </TableCell>

                    <TableCell

                    >
                      Nome
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Contrato
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}

                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={data?.rent_contract.number}
                    hover
                    sx={{cursor:"pointer"}}
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/contratos/${data?.rent_contract.id}`)}}
                      >
                      <TableCell>
                      {data?.rent_contract.number}
                      </TableCell>
                    <TableCell>
                      {data?.rent_contract.name}
                    </TableCell>
                    <TableCell sx={{
                      textAlign: "center",
                    }}>
                      {data?.rent_contract.contract_type}
                    </TableCell>
                    <TableCell sx={{
                      textAlign: "center",
                    }}>
                      {data?.rent_contract.status}
                    </TableCell>
                    <TableCell sx={{fontWeight: "bold", textAlign: "center"}}>
                      {Masks.money(data?.rent_contract.value.toString())}
                    </TableCell>

                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card sx={{padding:"1rem", margin: "1rem " }}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", justifyContent:"space-between", padding: ".5rem 0 "}}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Receipt  sx={{
                    color: 'primary.main'
                  }}/>
                  <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                    Fatura</Typography>
                </Box>

              </Box>
              <Divider/>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell

                    >
                      Nº da Fatura
                    </TableCell>

                      <TableCell

                      >
                        Imóvel
                      </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Referência
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Vencimento
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}

                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",

                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableRow
                key={data.invoice.number}
                hover
                onClick={(e) => {
                e.stopPropagation()
                navigate(`/faturas/${data?.invoice.id}`)
              }}
                sx={{ 'cursor': 'pointer', }}
                >
                <TableCell>
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                  >

                    {data?.invoice.number}
                  </Box>
                </TableCell>
                <TableCell>
                  {data?.invoice.unit}
                </TableCell>
                <TableCell
                  sx={{
                  textAlign: "center",
                }}
                >
                  {data?.invoice.reference_month}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {data?.invoice.due_date
                    ? moment(data?.invoice.due_date).format('DD/MM/YYYY') : '-'
                  }
                </TableCell>
                <TableCell
                  align={'center'}
                >
                  {data?.invoice.status}


                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    textAlign: "center"
                  }}
                >
                  {data?.invoice.total.toString().includes('-') && '- '}
                  {Masks.money(data?.invoice.total.toString())}
                </TableCell>


              </TableRow>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid md={4}>
          <Card >
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", justifyContent:"space-between", padding: ".5rem 0 "}}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                <Alarm  sx={{
                  color: 'primary.main'
                }}/>
                <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Dados da Transferência</Typography>

                </Box>
                {data?.user && <Tooltip title={`Transferência realizada por ${data?.user.name}`}>
                  <Avatar alt={data?.user.name} src={data?.user?.photo}  sx={{ width: 24, height: 24, bgcolor: "secondary.main", fontSize:"small"  }} />
                </Tooltip> }
              </Box>

              <Divider/>


              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell><Chip
                      color={data?.status.id === 'PAID' ? 'success' :
                        data?.status.id === 'PENDING' ? 'warning' :
                          data?.status.id === 'SCHEDULED' ? 'warning' :
                            data?.status.id === 'FINALIZED' ? 'success' :
                            data?.status.id === 'PROCESSING' ? 'primary' :
                              data?.status.id === 'ERROR' ? 'error' :
                                data?.status.id === 'DUE' ? 'error' :
                                  data?.status.id === 'FAILED' ? 'error' :
                                    data?.status.id === 'OPEN' && 'info'}
                      size="small"
                      variant="outlined"
                      label={data?.status.value}/></TableCell>
                  </TableRow>
                  {data?.status_message && <TableRow>
                    <TableCell>Mensagem</TableCell>
                    <TableCell>{data?.status_message}</TableCell>
                  </TableRow> }
                  <TableRow>
                    <TableCell>Data/Hora</TableCell>
                    <TableCell>{moment(data?.auto_transfer_scheduled).format("DD/MM/YYYY [às] HH:mm")}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>{data?.auto_transfer_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recibo</TableCell>
                    <TableCell><Link href={data?.receipt} rel="noopener" target="_blank">Ver Recibo</Link></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>

          </Card>

          <Card sx={{marginTop: "1rem"}}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
              <ContactMailOutlined  sx={{
                color: 'primary.main'
              }} />
              <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Beneficiário</Typography>
              </Box>
              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>{data?.contract_recipient?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-mail</TableCell>
                    <TableCell>{data?.contract_recipient?.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Telefone</TableCell>
                    <TableCell>{data?.contract_recipient?.phone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>



            </CardContent>

          </Card>



          { data?.bank_account_recipient.account_type === "TED" && <Card sx={{marginTop: "1rem"}}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                <AccountBalance  sx={{
                  color: 'primary.main'
                }} />
                <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Dados Bancarios:</Typography>
              </Box>

              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tipo da conta</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_account_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Agência</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_branch}-{data?.bank_account_recipient.bank_branch_digit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Conta</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank_account}{data?.bank_account_recipient.bank_account_digit && `-${data?.bank_account_recipient.bank_account_digit}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>



            </CardContent>

          </Card> }

          { data?.bank_account_recipient.account_type === "PIX" && <Card sx={{marginTop: "1rem"}}>
            <CardContent >
              <Box sx={{display: "flex", alignItems: "center", padding: ".5rem 0 "}}>
                <PixOutlined  sx={{
                  color: 'primary.main'
                }} />
                <Typography color={"primary.main"} sx={{marginLeft: ".5rem"}} variant="h6">
                  Dados do Pix:</Typography>
              </Box>

              <Divider/>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Banco</TableCell>
                    <TableCell>{data?.bank_account_recipient.bank}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chave Pix</TableCell>
                    <TableCell>{data?.bank_account_recipient.pix_key_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chave</TableCell>
                    <TableCell>{data?.bank_account_recipient.pix_key_value}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>



            </CardContent>

          </Card> }

        </Grid>

      </Grid> }
   </>

  )
}

export default SignedRecipientTransferDetail
