import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography
} from '@mui/material'
import { ReceiptLong } from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { api } from '../../../services/Main/Api'
import CircularProgress from '@mui/material/CircularProgress'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import DeleteIcon from '@mui/icons-material/Delete'

function RentContractFeeDetail(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/rent-contract/fee/${props.idFee}/`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [props.idFee]);

  function handleDelete(itemId){
    setLoading(true)
    api.delete(`rent-contract/fee/${itemId.id}/`).then(response => {
      props.loadRentContractFee()
      props.handleDialogClose(true)
      setLoading(false)

    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }
    return (
      <>
      {!loading && data ? <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={props.openDialog}
        onClose={props.handleDialogClose}
      >
        <DialogTitle>
          <Box sx={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
            <Box sx={{display:"flex", alignItems: "center"}}>
              <Avatar sx={{bgcolor: 'primary.main'}}><ReceiptLong/></Avatar>
              <Typography variant={"h5"} sx={{ml:1}}>COB-{props.idFee}</Typography>
            </Box>

            <Box>
              { data.recurring === true ? <Chip icon={<AllInclusiveOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Recorrente"} />
               : data.installment === true ? <Chip icon={<CreditScoreOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Parcelada"} />
              : <Chip icon={<CalendarMonthOutlinedIcon />} variant={"outlined"} color={"info"} label={"Cobrança Mensal"} /> }
            </Box>
          </Box>
          </DialogTitle>
        <Divider/>

        <DialogContent>

          <Box>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">
                  { data.recurring === true ? "Inicio": "Data"} da Cobrança</Typography>

                {moment(`${data?.year}-${data?.month}`, "YYYY-MM").format("MMMM [de] YYYY")}

              </Grid>
              {data?.recurring_type && <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">
                  Final da Cobrança</Typography>
                { data.recurring_type !== "EVERYDAY" ?<Box>
                {moment(`${data?.recurring_end_year}-${data?.recurring_end_month}`, "YYYY-MM").format("MMMM [de] YYYY")}
                </Box> :
                  "Sem data de término" }
              </Grid> }
              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Valor</Typography>
                {Masks.money(data.value.toString())}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Categoria</Typography>
                {data.category}
              </Grid>

              {data.description && <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Descrição</Typography>
                {data.description}
              </Grid> }

              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Fatura</Typography>
                {data.invoice_rule}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Repasse</Typography>
                {data.transfer_rule}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Calculo proporcional</Typography>
                {data.apply_proportional_calculation === true ? "Sim" : "Não"}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Incluir no IRRF</Typography>
                {data.include_income_tax === true ? "Sim" : "Não"}
              </Grid>
              { data.installment === true && <>
                <Grid item xs={12} md={4}>
                <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Número de parcelas </Typography>
                {data.installment_total_number} vezes
              </Grid>
                {data.installment_start > 0 && <Grid item xs={12} md={4}>
                  <Typography sx={{fontWeight:"bold"}} variant="subtitle1">Parcelamento Iniciou </Typography>
                  {data.installment_start}ª Parcela
                </Grid> }
              </>}
            </Grid>

          </Box>
          { data.installment === true && <>
          <Divider sx={{ margin: "2rem 0", color:"primary.main", fontWeight:"normal"}}>Cobrança Parcelada em {data.installment_total_number} vezes</Divider>

          <TableContainer>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={"left"}>Código</TableCell>
                    <TableCell>Parcela</TableCell>
                    <TableCell >Referencia</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { data.installment_fees.map(item => (
                      <TableRow>
                        <TableCell align={"left"}>{item.name}</TableCell>
                        <TableCell>{item.index}ª Parcela </TableCell>
                        <TableCell>{moment(item.reference_date, "MM-YYYY").format("MMM[/]YYYY")}</TableCell>
                        <TableCell>{Masks.money(item.value.toString())}</TableCell>
                        <TableCell>
                          <Chip
                            color={item?.status.key === 'paid' ? 'success' :
                              item?.status.key === 'pending' ? 'warning' :
                                item?.status.key === 'FINALIZED' ? 'success' :
                                  item?.status.key === 'overdue' ? 'error' :
                                    item?.status.key === 'FAILED' ? 'error' :
                                      item?.status.key === 'open' && 'info'}
                            size="small"
                            variant="outlined"
                            label={item?.status.value}/>

                        </TableCell>

                      </TableRow>
                    ))}
                    </TableBody>
              </Table>
            </TableContainer>
          </> }

          { data.recurring === true && <>
            <Divider sx={{ margin: "2rem 0", color:"primary.main", fontWeight:"normal"}}>
              Cobrança recorrente {data.recurring_type !== "EVERYDAY" && `até ${moment(`${data?.recurring_end_year}-${data?.recurring_end_month}`, "YYYY-MM").format("MMMM [de] YYYY")}`}
            </Divider>

            <TableContainer>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={"left"}>Código</TableCell>
                    <TableCell>Mês/Ano</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { data.recurring_fees.map(item => (
                    <TableRow>
                      <TableCell align={"left"}>{item.name}</TableCell>

                      <TableCell>{moment(item.reference_date, "MM-YYYY").format("MMM[/]YYYY")}</TableCell>
                      <TableCell>{Masks.money(item.value.toString())}</TableCell>
                      <TableCell>
                        <Tooltip title={item.invoice_number ? `${item.invoice_number}` : 'A fatura ainda não foi criada'}>
                        <Chip
                          color={item?.status.key === 'paid' ? 'success' :
                            item?.status.key === 'pending' ? 'warning' :
                              item?.status.key === 'FINALIZED' ? 'success' :
                                item?.status.key === 'overdue' ? 'error' :
                                  item?.status.key === 'FAILED' ? 'error' :
                                    item?.status.key === 'open' && 'info'}
                          size="small"
                          variant="outlined"
                          label={item?.status.value}/>
                        </Tooltip>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </> }
        </DialogContent>
        <Divider />
        <DialogActions sx={{display:"flex", justifyContent:"space-between", padding: "2rem 1rem"}}>

          <Button variant="outlined" size={"small"} startIcon={<DeleteIcon />} onClick={(e)=>{
            handleDelete(data)
          }}>
            Excluir Cobrança
          </Button>
          <Button variant="text" color={"secondary"} onClick={()=> props.handleDialogClose() }>Fechar</Button>

        </DialogActions>
      </Dialog> :
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </>
    )
}



export default RentContractFeeDetail