import React from "react";
import {
  TableCell,
  TableRow,
  Typography
} from "@mui/material";

const ExpenseSummaryInstallments = ({
    state,
    installments
  }) => {

  return (
    <>
      {state?.category !== 'DISCOUNT' &&
      <TableRow>
        <TableCell align={'right'}>
          <Typography>{'Parcelamento'}</Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontWeight: 'bold' }}>{installments}x</Typography>
        </TableCell>
      </TableRow>
      }
    </>
  )
}

export default ExpenseSummaryInstallments