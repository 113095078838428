import {Box, Fade} from "@mui/material";
import SimpleText from "../../../../components/Shared/SimpleText";
import React from "react";

const BailInsuranceInpunts =
  ({
    edit,
    guarantee,
    guaranteeData,
    setGuaranteeData
   }) => {
  return (
    <Box
      sx={{
        // paddingX:'1rem'
      }}
    >
      {guarantee === 'bail_insurance' && edit &&
        <Fade
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={300}
        >
          <Box>
            <SimpleText
              label={'Número de Apólice/Contrato'}
              onlyNumbers
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'insurance_policy_number'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Companhia'}
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'insurance_company'}
              styles={{ mb: '1rem' }}
            />
            <SimpleText
              label={'Valor do Seguro'}
              money
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'value'}
              styles={{ mb: '1rem' }}
            />

            <SimpleText
              label={'Parcelas'}
              onlyNumbers
              state={guaranteeData}
              setState={setGuaranteeData}
              keyName={'installments'}
              styles={{ mb: '1rem' }}
            />

          </Box>
        </Fade>
      }

    </Box>
  )
}

export default BailInsuranceInpunts