import {
  Alert,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import {Cancel, Delete, DescriptionOutlined, ForwardToInboxOutlined, NoteAdd, Pending} from "@mui/icons-material";
import React, {useContext, useState} from "react";
import SimpleDialog from "../../../../../../../components/Shared/SimpleDialog";
import moment from "moment/moment";
import {DatePicker} from "@mui/x-date-pickers";
import Context from "../../../../../../../contexts/Context";
import {api} from "../../../../../../../services/Main/Api";
import {useSnackbar} from "notistack";

const InvoiceDetailDeleteSlip = (props) => {
  const { invoice } = props
  const { dataProvider, setDataProvider } = useContext(Context)
  const { invoiceDueDate, refreshSlip } = dataProvider || {}
  const {enqueueSnackbar} = useSnackbar()


  const [openDialog, setOpenDialog] = useState(false)
  const dateFormat =  moment(invoiceDueDate).format('YYYY-MM-DD')
  const [reqData, setReqData] = useState({ due_date: dateFormat})
  const [requesting, setRequesting] = useState(false)

  function deleteSlip () {
    setRequesting(true)

    api.delete(`bank-slip/invoice/${invoice?.id}/`)
      .then(() => {
        enqueueSnackbar("Boleto cancelado!", {variant: 'success'})
        setDataProvider(prevState => ({...prevState, refreshSlip: !refreshSlip}))
        setOpenDialog(false)
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar("Erro ao cancelar boleto", {variant: "error"})
      })
      .finally(() => {
        setRequesting(false)
      })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={requesting}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Tooltip
        title={'Cancelar Boleto'}
        arrow
        placement={'top'}
      >
        <span>
          <IconButton
            disabled={invoice?.status?.key === 'paid' || invoice?.bank_slip_status?.key === null }
            target={'_blank'}
            onClick={() => setOpenDialog(true)}
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
      <SimpleDialog
        title={'Cancelar boleto'}
        open={openDialog}
        setOpen={setOpenDialog}
        onClickConfirm={() => deleteSlip()}
        question={"Realmente deseja cancelar esse boleto?"}
      />
    </>
  )
}

export default InvoiceDetailDeleteSlip