import React, {
  useContext
} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  IconButton,
  Skeleton,
  useMediaQuery
} from "@mui/material";
import {
  KeyboardDoubleArrowDown,
  Receipt
} from "@mui/icons-material";
import DateSelect from "./DateSelect";
import Statements from "./Statements";
import Context from "../../../../../contexts/Context";

const InquiryStatement = ({ isLoading, data, setData, hasData, setHasData  }) => {
  const { filters, setFilters, dataProvider } = useContext(Context)

  const sizeMatch = useMediaQuery('@media (min-width:900px)')

  const loadMore = () => {
    if (!hasData) {
      console.log('terminou')
    } else {
      setFilters({
        ...filters,
        page_index: filters?.page_index + 1,
      })
    }
  }

  return (
    <>
        {!dataProvider?.loadingStatement ?
          <Card
            sx={{
              width: '100%',
            }}
          >
            <CardHeader
              title={'Consulta - Extrato'}
              avatar={<Avatar sx={{bgcolor: "primary.main"}}><Receipt/></Avatar>}
            />
            <Divider/>
            <CardContent
              sx={{
                p: sizeMatch ? '2rem' : '1rem'
              }}
            >
              <DateSelect
                setHasData={setHasData}
                setData={setData}
              />
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Fade
                      in={!hasData}
                      mountOnEnter
                      unmountOnExit
                      timeout={500}
                      sx={{
                        marginY: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        onClick={
                          () => setFilters((prevState) => ({...prevState, expand: !filters?.expand}))
                        }
                      >
                        <KeyboardDoubleArrowDown
                          sx={{
                            transform: !filters?.expand && 'rotate(180deg)',
                            color: !filters?.expand ? '#A2A2A2FF' : 'primary.main'
                          }}
                        />
                      </IconButton>
                    </Fade>
                  </Box>
                  <Statements
                    data={data}
                    hasData={hasData}
                  />
                </>

            </CardContent>
            {hasData &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                type={'button'}
                onClick={() => loadMore()}
                >
                 Carregar mais
                </Button>
              </Box>
            }
          </Card>
          :
          <Skeleton
            variant="rounded"
            animation="pulse"
            height={800}
            sx={{
              width: '100%'
            }}
          />
        }

    </>

  )
}

export default InquiryStatement