import { Box, Card, CardContent } from '@mui/material'
import Menu from '@mui/material/Menu'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {
  ApartmentOutlined,
  CategoryOutlined,
  DomainOutlined,
  FileCopy,
  Summarize,
  ViewModule,
  Villa
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import {useContext, useState} from 'react'
import Context from "../../../contexts/Context";
import { getCondoType } from '../../../services/Main/AuthStorage'

const SettingsSidebar = () => {
  const { user } = useContext(Context)
  const {perm_expense, perm_rent} = user || {}


  const location = useLocation()

  return (
    <>
      <Box>
        <Card>
          <CardContent>
            <List component={'nav'}>

              <ListItemButton
                href={"/configuracoes/contas"}
                selected={location.pathname === "/configuracoes/contas"}
              >
                <ListItemIcon><Villa/></ListItemIcon>
                <ListItemText primary={'Contas'}/>
              </ListItemButton>

              <ListItemButton
                href={"/configuracoes/dadosconta"}
                selected={location.pathname === "/configuracoes/dadosconta"}
              >
                <ListItemIcon><ApartmentOutlined/></ListItemIcon>
                <ListItemText primary={'Dados da Conta'}/>
              </ListItemButton>


              {perm_expense === true &&
                <ListItemButton
                  href={"/configuracoes/bloco"}
                  selected={location.pathname === "/configuracoes/bloco"}
                >
                  <ListItemIcon><DomainOutlined/></ListItemIcon>
                  <ListItemText primary={'Blocos'}/>
                </ListItemButton>
              }

              {getCondoType() === "CONDO" &&
                <ListItemButton
                  href={"/configuracoes/categorias"}
                  selected={location.pathname === "/configuracoes/categorias"}
                >
                  <ListItemIcon><CategoryOutlined/></ListItemIcon>
                  <ListItemText primary={'Categorias de Despesas'}/>
                </ListItemButton>
              }

              {perm_rent === true && <ListItemButton
                href={"/configuracoes/relatorios"}
                selected={location.pathname === "/configuracoes/relatorios"}
              >
                <ListItemIcon><Summarize/></ListItemIcon>
                <ListItemText primary={'Relatórios'}/>
              </ListItemButton> }

              <ListItemButton
                href={"/configuracoes/documentos"}
                selected={location.pathname === "/configuracoes/documentos"}
              >
                <ListItemIcon><FileCopy/></ListItemIcon>
                <ListItemText primary={'Documentos'}/>
              </ListItemButton>
            </List>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default SettingsSidebar
