import {
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import moment from "moment/moment";
import Masks from "../../../components/Shared/Masks";
import React, {useContext, useState} from "react";
import Context from "../../../contexts/Context";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Close, Delete, MoreVert} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";

const RentTableBody = (props) => {
  const { state, setReqLoading, reqLoading } = props
  const { filters, setFilters, unitOptionsNumber } = useContext(Context)
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()


  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [selectedRent, setSelectedRent] = useState(null)

  const deleteReq = () => {
    setReqLoading(true)
    api.delete(`/rent_contract/${selectedRent?.id}/`)
      .then(response =>{
        setFilters(prevState => ({...prevState, deleteDone: !filters?.deleteDone}))
        enqueueSnackbar('Locação apagado', { variant: 'success' })
      })
      .catch(error => {
        !!error?.response?.data[0] && enqueueSnackbar(error.response?.data[0], { variant: 'error' })
        enqueueSnackbar('Erro ao excluir contrato', { variant: 'error' })
        console.log(error)
      })
      .finally(() => {
        setReqLoading(false)
      })
  }

  function statusFilter(param) {
    switch (param) {
      case 'active':
        return 'Ativo';
      case 'inactivating':
        return 'Encerrando';
      case 'inactive':
        return 'Encerrado';
      case 'pending':
        return 'Pendente';
      default:
        return '- - -';
    }
  }
  console.log(statusFilter())

  function statusColor(param) {
    switch (param) {
      case 'active':
        return 'success';
      case 'inactivating':
        return 'secondary';
      case 'inactive':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'black';
    }
  }

  function contractType(param) {
    switch (param) {
      case 'commercial':
        return 'Comercial';
      case 'residencial':
        return 'Residencial';
      default:
        return '- - -';
    }
  }

  function ownersLength(param){
      if (param?.length === 1) {
        return param[0]?.['name']
      } else if (param?.length > 1) {
        return `${param[0]?.['name']}, +${Math.round(param?.length - 1)} ...`
      } else {
        return "Não informado"
      }
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  return (
    <>
    <TableBody>
      { state?.results?.map((rent, index) => (
        <TableRow
          hover
          tabIndex={-1}
          key={rent?.id}
          sx={{ 'cursor': 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/contratos/${rent?.id}/`)
            setFilters(prevState => ({...prevState, isUpdate: true , id: rent?.id, load: true}))
          }}
        >
          <TableCell
            component="th"
            scope="row"
          >
            <strong>BB3-{zeroPad(rent?.id, 4) ?? <Close sx={{color: 'error.main'}}/>}</strong>
          </TableCell>

          <TableCell
            component="th"
            scope="row"
          >
            {rent?.name ?? <Close sx={{color: 'error.main'}}/>}
          </TableCell>

          <TableCell
            component="th"
            scope="row"
          >
            {ownersLength(rent?.owners)}
            <Typography
              sx={{
                fontSize: '11px'
              }}
            >
              Número: {rent?.unit?.number}
            </Typography>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {contractType(rent?.contract_type)}
          </TableCell>

          <TableCell
            component="th"
            scope="row"
          >
            {moment(rent?.active_date).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
          >
            {Masks.money(String(rent?.value)) ?? ''}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={'center'}
            sx={{
              fontWeight: 'bold',
            }}
          >
            <Chip
              size={'small'}
              label={statusFilter(rent.status)}
              color={statusColor(rent.status)}
              variant="outlined"
            />

          </TableCell>






          <TableCell
            component="th"
            scope="row"
            sx={{
              width: '24px'
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedRent(rent)
                setAnchor(e.currentTarget)
                setOpen(rent.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === rent.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpenDelete(true)
                  setOpen(false)
                  e.stopPropagation()
                }}
                // disabled={invoice?.status?.key !== "open"}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText
                  primary="Excluir contrato"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>


            </Menu>
          </TableCell>
        </TableRow>
      ))}

    </TableBody>
    <SimpleDialog
      open={openDelete}
      setOpen={setOpenDelete}
      title={'Apagar Locação'}
      question={'Gostaria realmente de apagar esta locação?'}
      functionProp={deleteReq}
    />
    </>
  )
}

export default RentTableBody