import { AppBar, Box, IconButton, Toolbar, Button } from '@mui/material'
import UserMenu from '../../components/Shared/UserMenu'
import React, {useContext, useState} from 'react'
import BreadCrumbs from './BreadCrumbs'
import { MenuOutlined } from '@mui/icons-material'
import NotificationMenu from '../../components/Shared/NotificationMenu'
import BalanceBar from '../../components/Bank/BalanceBar'
import SelectedCondo from "../../components/Shared/SelectedCondo";
import Context from "../../contexts/Context";

const drawerWidth = 250

const Navbar = ({ handleDrawerToggle }) => {
  const { user } = useContext(Context)
  const { perm_bank } = user || {}


  const [req, setReq] = useState(null)

  return (
    <Box sx={{ mb: 10 }}>
      <AppBar
        position={'fixed'}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: 'white',
          boxShadow: 'rgb(32 32 32 / 6%) 0px 8px 16px;',
        }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton
            color="primary"
            aria-label="Abrir Barra"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuOutlined/>
          </IconButton>
          <BreadCrumbs/>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <SelectedCondo />
            { perm_bank === true &&
              <Box
                onClick={() => setReq(prevState => !prevState)}
                >
                <BalanceBar req={req}/>
              </Box>
            }
            <NotificationMenu/>
            <UserMenu/>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default Navbar
